import msFn from 'ms';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { GetSpotifyAccessTokenDocument } from '../../graphql/generated';
import { SpotifyConnectState } from './useSpotifyAuth';

gql(/* GraphQL */ `
  query GetSpotifyAccessToken($authCode: String) {
    spotifyAuthAccessToken(authCode: $authCode) {
      __typename
      ... on QuerySpotifyAuthAccessTokenSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }
`);

const fiftyNineMinutes = msFn('59 minutes');

export function useSpotifyAccessToken() {
  const { value } = SpotifyConnectState.useStore();
  const authCode = value?.code;

  const { loggedInUser } = useAuthContext();

  const { data, isLoading, isError, refetch } = useQuery(GetSpotifyAccessTokenDocument, {
    staleTime: fiftyNineMinutes,
    refetchInterval: fiftyNineMinutes,
    variables: { authCode },
    enabled: authCode != null || loggedInUser?.spotifyAuthConnection?.spotifyUserId != null,
    filterQueryKey: {
      authCode,
      state: value?.state,
      spotifyUserId: loggedInUser?.spotifyAuthConnection?.spotifyUserId,
    },
    select(data) {
      if (data.data.spotifyAuthAccessToken.__typename !== 'QuerySpotifyAuthAccessTokenSuccess') {
        return null;
      }
      return data.data.spotifyAuthAccessToken.data;
    },
    keepPreviousData: loggedInUser != null,
  });

  return {
    accessToken: data,
    isLoading,
    isError,
    refetchToken: refetch,
  };
}
