import { useEffect, useMemo, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation, useQuery } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  ArtistByHandleDocument,
  ArtistProfileSettingsDocument,
  ArtistSettingsViewFragmentDoc,
  AuthUserDocument,
  CustomizeVaultDocument,
  CustomizeVaultViewFragmentDoc,
  getFragment,
  VaultCustomizationDocument,
  VaultThemeByArtistHandleDocument,
  VaultThemeByVaultIdDocument,
} from '../../../graphql/generated';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { generateAccentColors } from '../../../utils/colorUtils';
import { customizeMenuValidationSchema, type CustomizeMenuValidationSchema } from './schema';

gql(/* GraphQL */ `
  mutation CustomizeVault($input: MutationCustomizeVaultInput!) {
    customizeVault(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationCustomizeVaultSuccess {
        data {
          id
          ...customizeVaultView
        }
      }
    }
  }

  query VaultCustomization($vaultId: UUID!) {
    vaultFromId(vaultId: $vaultId) {
      id
      ...customizeVaultView
    }
  }
`);

RefetchOnComplete({
  trigger: [CustomizeVaultDocument],
  refetch: [
    ArtistByHandleDocument,
    VaultThemeByArtistHandleDocument,
    VaultThemeByVaultIdDocument,
    VaultCustomizationDocument,
    CustomizeVaultViewFragmentDoc,
    ArtistProfileSettingsDocument,
    AuthUserDocument,
  ],
});

export function useCustomize({ vaultId }: { vaultId: string | null | undefined }) {
  const { loggedInUser } = useAuthContext();
  const { openToast } = useToast();

  const vaultDataInitialized = useRef(false);
  const vaultTheme = useSnapshot(VaultThemeStore);

  const [isActionPending, setActionPending] = useState(false);
  const [activeTab, setActiveTab] = useState<'profile' | 'theme'>('profile');

  const { mutateAsync: customizeVault, isLoading: isCustomizing } = useMutation(
    CustomizeVaultDocument,
    {
      onError: e => {
        openToast({
          text: e.message,
          variant: 'error',
        });
      },
    },
  );

  const {
    data: vaultData,
    isLoading: isLoadingVault,
    isError: isVaultError,
    refetch: refetchVault,
  } = useQuery(VaultCustomizationDocument, {
    staleTime: 0,
    cacheTime: 0,
    variables: !!vaultId && {
      vaultId,
    },
  });

  const {
    data: artistProfileData,
    isError: isArtistProfileError,
    isLoading: isLoadingArtistProfile,
    refetch: refetchArtistProfile,
  } = useQuery(ArtistProfileSettingsDocument, {
    staleTime: 0,
    cacheTime: 0,
    variables: !!loggedInUser?.artist?.id && { artistId: loggedInUser.artist.id },
  });

  const vault = getFragment(CustomizeVaultViewFragmentDoc, vaultData?.data.vaultFromId);
  const artistProfile = getFragment(
    ArtistSettingsViewFragmentDoc,
    artistProfileData?.data.artistById,
  );

  const isError = isVaultError || isArtistProfileError;
  const isLoading =
    isLoadingVault ||
    vault == null ||
    isLoadingArtistProfile ||
    artistProfileData?.data.artistById == null;

  const [temporaryProfileImageUrl, setTemporaryProfileImageUrl] = useState<string | null>(
    vaultTheme.profileImageUrl,
  );

  const [temporaryLogoUrl, setTemporaryLogoUrl] = useState<string | null>(vaultTheme.logoMediaUrl);

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<CustomizeMenuValidationSchema>({
    defaultValues: {
      shouldRemoveLogo: false,
    },
    resolver: zodResolver(customizeMenuValidationSchema),
  });

  useEffect(() => {
    if (isLoading || !vault || !artistProfile || vaultDataInitialized.current) return;

    // For customize vault
    setValue('accentColor', vaultTheme.accentColor || vault?.accentColor || null);
    setValue('backgroundColor', vaultTheme.backgroundColor || vault?.backgroundColor || null);
    setValue('logoMediaId', vault?.logoImage?.id || null);
    // For customize artist profile
    setValue('profileImageMediaId', vault?.artistProfile?.profileImage?.id || null);
    setTemporaryLogoUrl(vault?.logoImage?.url || null);
    setTemporaryProfileImageUrl(vault?.artistProfile?.profileImage?.url || null);
    setValue('name', artistProfile?.name || '');
    setValue('customWebsiteUrl', artistProfile?.customWebsiteUrl || '');
    setValue('instagramHandle', artistProfile?.instagramHandle || '');
    setValue('spotifyUrl', artistProfile?.spotifyUrl || '');
    setValue('tiktokHandle', artistProfile?.tiktokHandle || '');

    vaultDataInitialized.current = true;
  }, [vault, setValue, vaultTheme, artistProfile, isLoading]);

  const {
    accentColor: formAccentColor,
    backgroundColor: formBackgroundColor,
    name: formName,
  } = useWatch({
    control,
  });

  const accents = useMemo(
    () => (formBackgroundColor ? generateAccentColors(formBackgroundColor).accents : null),
    [formBackgroundColor],
  );

  return {
    accents,
    activeTab,
    artistProfile,
    customizeVault,
    errors,
    formAccentColor,
    formBackgroundColor,
    formName,
    handleSubmit,
    isActionPending,
    isCustomizing,
    isError,
    isLoading,
    isSubmitting,
    refetchArtistProfile,
    refetchVault,
    register,
    reset,
    setActionPending,
    setActiveTab,
    setTemporaryLogoUrl,
    setTemporaryProfileImageUrl,
    setValue,
    temporaryLogoUrl,
    temporaryProfileImageUrl,
    vault,
  };
}
