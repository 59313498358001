import { useCallback, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException, captureMessage } from '@sentry/react';
import clsx from 'clsx';
import { orderBy } from 'lodash-es';
import { Virtuoso, type VirtuosoHandle } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faLock, faMessage } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faClose, faPlusCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUp } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { uuidv4 } from '@soundxyz/utils';
import { useAudioController } from '../../audio/AudioController';
import { useAudioPosition } from '../../audio/AudioPosition';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useInfiniteQuery, useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  AddCommentDocument,
  DeleteCommentDocument,
  type FragmentType,
  GetBanStatusDocument,
  getFragment,
  makeFragmentData,
  MessagesByIdDocument,
  MessageSource,
  ReactCommentDocument,
  RemoveReactCommentDocument,
  ReplyToMessageFragmentDoc,
  TierTypename,
  type TrackCommentRowFragment,
  TrackCommentRowFragmentDoc,
  TrackCommentsDocument,
  TrackContentByIdDocument,
  TrackThreadCommentsDocument,
  type VaultContentLandingPageFragment,
  VaultType,
} from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useWindow } from '../../hooks/useWindow';
import type { TrackCommentsBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import type { TypeFromGraphQLUnion } from '../../types/gql';
import { secondsToTimestamp, type TimestampString } from '../../utils/timestampParse';
import { Button } from '../buttons/Button';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { TrackCommentRow } from '../comments/TrackCommentRow';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { closeFullScreen } from '../main/AudioPlayer';
import {
  getTrackCommentsOwnComments,
  useTrackCommentsOwnComments,
} from '../views/hooks/useTrackCommentsReplies';

gql(/* GraphQL */ `
  mutation ReactComment($input: MutationCreateMessageReactionInput!) {
    createMessageReaction(input: $input) {
      __typename
      ... on MutationCreateMessageReactionSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation RemoveReactComment($input: MutationDeleteMessageReactionInput!) {
    deleteMessageReaction(input: $input) {
      __typename
      ... on MutationDeleteMessageReactionSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation AddComment($input: MutationCreateMessageInput!, $asArtistId: UUID) {
    createMessage(input: $input) {
      __typename
      ... on MutationCreateMessageSuccess {
        data {
          id
          createdAt
          ...TrackCommentRow
        }
      }
      ... on Error {
        message
      }
    }
  }

  query TrackComments($vaultContentId: UUID!, $asArtistId: UUID, $after: String, $first: Int) {
    vaultContentComments(vaultContentId: $vaultContentId, first: $first, after: $after) {
      edges {
        node {
          id
          createdAt
          ...TrackCommentRow
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  mutation DeleteComment($input: MutationDeleteMessageInput!) {
    deleteMessage(input: $input) {
      __typename
      ... on MutationDeleteMessageSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [
    ReactCommentDocument,
    RemoveReactCommentDocument,
    AddCommentDocument,
    DeleteCommentDocument,
  ],
  refetch: [
    TrackContentByIdDocument,
    TrackCommentsDocument,
    TrackThreadCommentsDocument,
    MessagesByIdDocument,
  ],
});

export function TrackCommentsBottomsheet({
  trackId,
  setIsScrolling,
  autoFocusInput,
  withVaultTheme,
}: TrackCommentsBottomsheetProps & { setIsScrolling: (isScrolling: boolean) => void }) {
  const { loggedInUser } = useAuthContext();

  const { data: content, isLoading } = useQuery(TrackContentByIdDocument, {
    variables: {
      vaultContentId: trackId,
      asArtistId: loggedInUser?.artist?.id,
    },
    staleTime: 0,
    select(data) {
      if (data.data.vaultContentById?.__typename !== 'VaultTrack') return null;

      return data.data.vaultContentById;
    },
  });

  const { closeBottomsheet } = useBottomsheetContainer();

  const { closeExtraBottomsheet } = useExtraBottomsheetContainer();

  const { isDesktop } = useWindow();

  const { activeTrackId } = useAudioController();

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: content?.vault?.activeSubscription,
    isOwner: !!content?.vault.isUserArtistAdmin,
  });

  const [replyToComment, setReplyToComment] = useState<{
    comment: TrackCommentRowFragment;
    userDisplayName: string;
    rootIndex: number;
  } | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  if (isLoading) {
    return (
      <View className="flex h-full w-full flex-col items-center">
        <View className="flex w-full flex-col items-center">
          <h2
            className={twMerge(
              'pb-4 pt-4 font-title !text-title-m font-medium',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          >
            Track comments
          </h2>

          <View
            className={twMerge(
              'flex h-[1px] w-[200%] flex-row',
              withVaultTheme ? 'bg-transparent' : 'bg-base700',
            )}
          />
        </View>
        <View className="flex h-full w-full flex-col items-center justify-between">
          <View className="flex w-full flex-col items-center gap-3 py-3">
            <View className="flex h-[300px] w-full flex-col">
              <SkeletonComment
                isAuthor={false}
                className="rotate-180 scale-x-[-1] pt-3"
                withVaultTheme={withVaultTheme}
              />
              <SkeletonComment
                isAuthor={false}
                className="rotate-180 scale-x-[-1] pt-3"
                withVaultTheme={withVaultTheme}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }

  if (content == null) {
    return (
      <View className="flex h-full w-full flex-col items-center">
        <View className="flex w-full flex-col items-center">
          <h2
            className={twMerge(
              'pb-4 pt-4 font-title !text-title-m font-medium',
              withVaultTheme && 'text-vault_text',
            )}
          >
            Track comments
          </h2>

          <View
            className={twMerge(
              'flex h-[1px] w-[200%] flex-row',
              withVaultTheme ? 'bg-vault_text/5' : 'bg-base700',
            )}
          />
        </View>
        <View className="flex h-full w-full flex-col items-center justify-between">
          <ErrorTrackComments withVaultTheme={withVaultTheme} />
        </View>
      </View>
    );
  }

  const isFreeTierSubscription = activeSubscriptionFeatures?.tier !== TierTypename.PaidTier;
  const hasTrackCommentReadAccess =
    activeSubscriptionFeatures?.enabledFeatures.TrackCommentsRead === true;
  const hasTrackCommentWriteAccess =
    activeSubscriptionFeatures?.enabledFeatures.TrackCommentsWrite === true;

  if (!hasTrackCommentReadAccess) {
    return (
      <View className="flex h-full w-full flex-col items-center justify-center p-4 text-center">
        <FontAwesomeIcon
          className={twMerge('mt-8', withVaultTheme && 'text-vault_text')}
          icon={faLock}
          fontSize={60}
        />
        <Text
          className={twMerge(
            'mt-6 !text-base-l',
            withVaultTheme ? 'text-vault_text' : 'text-base50',
          )}
        >
          {content.vault.type === VaultType.FreeOnly
            ? 'Track comments are locked'
            : 'Upgrade to All Access to view comments'}
        </Text>
        {content.vault.artist && content.vault.type !== VaultType.FreeOnly && (
          <View className="mb-8 mt-3">
            <SubscribeButton
              label={`Unlock for $${content.vault.price || DEFAULT_PRICE} / month`}
              linkValue={content.vault.artist.linkValue}
              artistAvatarUrl={content.vault.artist.profileImage?.url}
              price={content.vault.price || DEFAULT_PRICE}
              vaultId={content.vault.id}
              className={withVaultTheme ? 'bg-vault_accent text-vault_accent_text' : undefined}
              showBottomSheet={false}
              component="locked_track_comment_write_access"
              onClick={() => {
                closeExtraBottomsheet();
                closeBottomsheet();
                closeFullScreen();
              }}
            />
          </View>
        )}
      </View>
    );
  }

  return (
    <View className="flex h-full w-full flex-col items-center">
      <View className="flex w-full flex-col items-center justify-center">
        <h2
          className={twMerge(
            'pt-4 font-title !text-title-m font-medium',
            content.title ? 'pb-2' : 'pb-4',
            withVaultTheme && 'text-vault_text',
          )}
        >
          Track comments
        </h2>
        {!!content.title && (
          <p
            className={twMerge(
              'mb-4 line-clamp-1 max-w-[80%] font-title !text-base-m font-normal',
              withVaultTheme ? 'text-vault_text/50' : 'text-base500',
            )}
          >
            {content.title}
          </p>
        )}

        <View
          className={twMerge(
            'flex h-[1px] w-[106%] flex-row',
            withVaultTheme ? 'bg-vault_text/5' : 'bg-base700',
          )}
        />
      </View>

      <View className="flex h-full w-full flex-col items-center justify-between">
        <View className="flex w-full flex-col items-center gap-3 py-3">
          {content.commentMessageCount > 0 || content.commentCaptionMessage ? (
            <View className="flex h-[300px] w-full flex-col">
              <TrackCommentsList
                withVaultTheme={withVaultTheme}
                content={content}
                setIsScrolling={setIsScrolling}
                hasTrackCommentsWriteAccess={hasTrackCommentWriteAccess}
                isFreeTierSubscription={isFreeTierSubscription}
                virtuosoRef={virtuosoRef}
                onReply={({ userDisplayName, comment, rootIndex }) => {
                  const commentFrag = getFragment(TrackCommentRowFragmentDoc, comment);
                  setReplyToComment({ comment: commentFrag, userDisplayName, rootIndex });
                  inputRef.current?.focus();
                }}
              />
            </View>
          ) : (
            <EmptyTrackComments withVaultTheme={withVaultTheme} />
          )}
        </View>

        <View
          className={twMerge(
            'flex h-full w-full flex-col items-center justify-end gap-4',
            !isDesktop && 'pb-2',
          )}
        >
          <View
            className={twMerge(
              'flex h-[1px] w-[106%] flex-row',
              withVaultTheme ? 'bg-vault_text/5' : 'bg-base700',
            )}
          />
          {hasTrackCommentWriteAccess ? (
            <>
              {replyToComment && (
                <View className="mx-4 flex w-full flex-row items-center justify-between">
                  <Text
                    className={twMerge(
                      '!text-base-m',
                      withVaultTheme ? 'text-vault_text' : 'text-white',
                    )}
                  >
                    Replying to {replyToComment.userDisplayName}
                  </Text>
                  <FontAwesomeIcon
                    icon={faClose}
                    size="lg"
                    className={twMerge(
                      'cursor-pointer',
                      withVaultTheme ? 'text-vault_text' : 'text-white',
                    )}
                    onClick={() => setReplyToComment(null)}
                  />
                </View>
              )}

              <AddCommentInput
                artistId={content.vault.artist?.id}
                messageChannelId={content.vault.messageChannelId}
                vaultContentId={content.id}
                withVaultTheme={withVaultTheme}
                isEnabledTimestampsButton={
                  hasTrackCommentWriteAccess &&
                  !isFreeTierSubscription &&
                  (!activeTrackId || activeTrackId === trackId)
                }
                hasTrackCommentsWriteAccess={hasTrackCommentWriteAccess}
                onSendComment={() => {
                  if (replyToComment?.rootIndex) {
                    virtuosoRef.current?.scrollToIndex({
                      index: replyToComment.rootIndex,
                      align: 'start',
                      behavior: 'smooth',
                    });
                  } else {
                    virtuosoRef.current?.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }
                }}
                autoFocusInput={autoFocusInput}
                activeSubscriptionTier={activeSubscriptionFeatures?.tier}
                replyToComment={replyToComment?.comment}
                setReplyToComment={setReplyToComment}
                inputRef={inputRef}
              />
            </>
          ) : (
            <View className="flex flex-col items-center justify-center gap-4 p-4">
              <Text
                className={twMerge(
                  '!text-base-l',
                  withVaultTheme ? 'text-vault_text/50' : 'text-base400',
                )}
              >
                {content.vault.type === VaultType.FreeOnly
                  ? 'Track comment writes are locked'
                  : 'Upgrade to All Access to leave a comment'}
              </Text>
              {content.vault.artist && content.vault.type !== VaultType.FreeOnly && (
                <View>
                  <SubscribeButton
                    label={`Unlock for $${content.vault.price || DEFAULT_PRICE} / month`}
                    linkValue={content.vault.artist.linkValue}
                    artistAvatarUrl={content.vault.artist.profileImage?.url}
                    price={content.vault.price || DEFAULT_PRICE}
                    vaultId={content.vault.id}
                    className={
                      withVaultTheme ? 'bg-vault_accent text-vault_accent_text' : undefined
                    }
                    showBottomSheet={false}
                    component="locked_track_comment_write_access"
                    onClick={() => {
                      closeExtraBottomsheet();
                      closeBottomsheet();
                      closeFullScreen();
                    }}
                  />
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

function EmptyTrackComments({ withVaultTheme }: { withVaultTheme: boolean }) {
  return (
    <View
      className={twMerge(
        'flex h-[380px] flex-col items-center justify-center gap-2',
        withVaultTheme && 'text-vault_text',
      )}
    >
      <FontAwesomeIcon icon={faMessage} className="h-[60px] w-[60px]" />
      <Text className="!text-title-s font-medium">No comments yet</Text>
      <Text
        className={twMerge('font-normal', withVaultTheme ? 'text-vault_text/50' : 'text-base400')}
      >
        Be the first to comment on this track
      </Text>
    </View>
  );
}

function ErrorTrackComments({ withVaultTheme }: { withVaultTheme: boolean }) {
  return (
    <View className="flex h-[380px] flex-col items-center justify-center gap-2">
      <Text
        className={twMerge(
          '!text-title-s font-medium',
          withVaultTheme ? 'text-vault_text' : 'text-white',
        )}
      >
        Comments not available
      </Text>
      <Text
        className={twMerge('font-normal', withVaultTheme ? 'text-vault_text/50' : 'text-base400')}
      >
        Please try again later
      </Text>
    </View>
  );
}

function AddCommentInput({
  artistId,
  messageChannelId,
  vaultContentId,
  isDisabled,
  isEnabledTimestampsButton,
  hasTrackCommentsWriteAccess,
  activeSubscriptionTier,
  onSendComment,
  autoFocusInput,
  setReplyToComment,
  replyToComment,
  inputRef,
  withVaultTheme,
}: {
  artistId: string | undefined | null;
  messageChannelId: string;
  vaultContentId: string;
  isDisabled?: boolean;
  isEnabledTimestampsButton: boolean;
  hasTrackCommentsWriteAccess: boolean;
  activeSubscriptionTier: TierTypename | null;
  onSendComment: () => void;
  autoFocusInput: boolean;
  setReplyToComment: React.Dispatch<
    React.SetStateAction<{
      comment: TrackCommentRowFragment;
      userDisplayName: string;
      rootIndex: number;
    } | null>
  >;
  replyToComment: TrackCommentRowFragment | undefined | null;
  inputRef: React.RefObject<HTMLInputElement>;
  withVaultTheme: boolean;
}) {
  const { mutate: addComment, isLoading: isSendingMessage } = useMutation(AddCommentDocument, {
    retry: 5,
  });

  const { loggedInUser } = useAuthContext();

  const asArtist = loggedInUser?.artist;

  const { data: isBanned = false } = useQuery(GetBanStatusDocument, {
    variables: !!artistId && { artistId },
    staleTime: 0,
    select(data) {
      return data.data.getBanStatus;
    },
  });

  const [content, setContent] = useState('');

  const disabled =
    !!isDisabled || isSendingMessage || isBanned || !loggedInUser || !hasTrackCommentsWriteAccess;

  const trimmedContent = content.trim();

  const { openToast } = useToast();

  const onSend = useStableCallback(() => {
    if (!loggedInUser) return;

    const optimisticId = uuidv4();

    const createdAt = new Date().toISOString();

    const TrackCommentsOwnComments = getTrackCommentsOwnComments(vaultContentId);

    const replyTo = replyToComment
      ? makeFragmentData(
          {
            id: replyToComment.id,
            user: replyToComment.user,
            content,
            createdAt,
            vaultContent: [],
            asArtist: replyToComment.asArtist,
            messageAttachments: [],
            source: replyToComment.source,
            activeSubscriptionTier: replyToComment.activeSubscriptionTier,
          },
          ReplyToMessageFragmentDoc,
        )
      : null;

    TrackCommentsOwnComments.set(optimisticId, {
      data: {
        vaultContentComments: {
          edges: [
            {
              node: {
                // @ts-expect-error Used to render as disabled until real comment is fetched back from refetch
                optimistic: true,

                id: optimisticId,
                createdAt,
                ...makeFragmentData(
                  {
                    id: optimisticId,
                    artistReactions: [],
                    source: MessageSource.ContentSection,
                    asArtist: asArtist
                      ? {
                          __typename: 'Artist',
                          id: asArtist.id,
                          linkValue: asArtist.mainLinkValue,
                          name: asArtist.name,
                          profileImage: asArtist.profileImage
                            ? {
                                id: asArtist.profileImage.id,
                                url: asArtist.profileImage.url,
                                dominantColor: null,
                              }
                            : null,
                          createdAt: loggedInUser.createdAt,
                        }
                      : null,
                    content: trimmedContent,
                    createdAt,
                    myReactionsInfo: [],
                    reactionsSummary: [],
                    user: {
                      __typename: 'PublicUser',
                      id: loggedInUser.id,
                      avatar: loggedInUser.avatar,
                      createdAt: loggedInUser.createdAt,
                      displayName: loggedInUser.displayName,
                      username: loggedInUser.username,
                    },
                    activeSubscriptionTier: asArtist ? null : activeSubscriptionTier,
                    replyTo,
                    threadMessagesPreview: [],
                    threadMessagesCount: 0,
                    threadRootId: replyToComment?.threadRootId || replyToComment?.id || null,
                    updatedAt: createdAt,
                  },
                  TrackCommentRowFragmentDoc,
                ),
              },
            },
          ],
          pageInfo: {
            hasNextPage: true,
            endCursor: null,
          },
        },
      },
    });

    onSendComment();

    function revert() {
      setReplyToComment(null);
      TrackCommentsOwnComments.delete(optimisticId);
    }

    addComment(
      {
        input: {
          optimisticId,
          content: trimmedContent,
          messageChannelId,
          asArtistId: asArtist?.id,
          source: 'CONTENT_SECTION',
          vaultContentId,
          replyToId: replyToComment?.id,
          threadRootId: replyToComment?.threadRootId || replyToComment?.id,
        },
        asArtistId: asArtist?.id,
      },
      {
        onSuccess({ data }) {
          switch (data.createMessage.__typename) {
            case 'MutationCreateMessageSuccess': {
              setContent('');
              setReplyToComment(null);
              const commentEdge =
                TrackCommentsOwnComments.get(optimisticId)?.data.vaultContentComments.edges[0];
              if (commentEdge) {
                commentEdge.node = data.createMessage.data;
              }
              return;
            }
            default: {
              captureMessage(data.createMessage.message, {
                extra: {
                  data,
                },
              });
              openToast({
                text: 'Failed to send comment. Please try again.',
                variant: 'error',
              });
              revert();
            }
          }
        },
        onError(error) {
          captureException(error, {
            extra: {
              error,
            },
          });
          openToast({
            text: 'Failed to send comment. Please try again.',
            variant: 'error',
          });
          revert();
        },
      },
    );
  });
  return (
    <View className="mb-[12px] flex w-full flex-row items-center justify-around gap-2">
      <View
        className={clsx(
          'flex w-full flex-row items-center rounded-[100px] py-[12px] pl-[20px] pr-[10px]',
          withVaultTheme ? 'bg-vault_text/10' : 'bg-base700',
          disabled && 'opacity-50',
        )}
      >
        <input
          className={twMerge(
            'w-full border-0 bg-transparent !text-base-l focus:font-normal focus:outline-none',
            withVaultTheme ? 'text-vault_text placeholder:text-vault_text/50' : 'text-white',
          )}
          placeholder="Add a comment"
          value={content}
          onChange={e => setContent(e.target.value)}
          disabled={disabled}
          autoFocus={autoFocusInput}
          ref={inputRef}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              setContent('');
              setReplyToComment(null);
              return;
            }

            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onSend();
              return;
            }
          }}
        />
        {isEnabledTimestampsButton && (
          <TrackCommentTimestampButton
            onClick={({ timestamp }) => {
              const endsWithWhitespace = /\s$/;

              if (endsWithWhitespace.test(content) || !content) {
                setContent(`${content}${timestamp} `);
              } else {
                setContent(`${content} ${timestamp} `);
              }
            }}
            isDisabled={disabled}
            withVaultTheme={withVaultTheme}
          />
        )}
      </View>
      {!!trimmedContent && (
        <Button
          iconOnly
          leadingIcon={faArrowUp}
          className={twMerge(
            'ml-[8px] mr-[8px] h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full p-0 text-[16px]/[20px] font-black',
            withVaultTheme ? 'bg-vault_accent text-vault_accent_text' : 'bg-yellow100 text-base800',
            disabled && 'bg-transparent',
          )}
          disabledClassName="opacity-30 transition-opacity"
          disabled={disabled}
          loading={isSendingMessage}
          label=""
          onClick={onSend}
          event={{
            type: EVENTS.SEND_COMMENT_MESSAGE,
            properties: {
              trackId: vaultContentId,
              messageChannelId,
              artistId,
              content: trimmedContent,
              isFreeTierSubscription: activeSubscriptionTier === TierTypename.FreeTier,
            },
          }}
        />
      )}
    </View>
  );
}

const SkeletonComment = ({
  isAuthor,
  className,
  withVaultTheme,
}: {
  isAuthor: boolean;
  className?: string;
  withVaultTheme: boolean;
}) => {
  return (
    <View
      className={twMerge(
        'flex w-full flex-row items-end pb-[10px]',
        isAuthor ? 'justify-end' : 'justify-start',
        className,
      )}
    >
      <LoadingSkeleton
        className={twMerge('mr-[4px] h-[30px] w-[30px]', withVaultTheme && 'bg-vault_text/10')}
      />
      <LoadingSkeleton
        className={twMerge(
          'flex h-[40px] w-full flex-col rounded-xl p-[12px]',
          withVaultTheme && 'bg-vault_text/10',
        )}
      />
    </View>
  );
};

function TrackCommentsList({
  content,
  setIsScrolling,
  hasTrackCommentsWriteAccess,
  isFreeTierSubscription,
  virtuosoRef,
  onReply,
  withVaultTheme,
}: {
  content: TypeFromGraphQLUnion<NonNullable<VaultContentLandingPageFragment>, 'VaultTrack'>;
  setIsScrolling: (isScrolling: boolean) => void;
  hasTrackCommentsWriteAccess: boolean;
  isFreeTierSubscription: boolean;
  virtuosoRef: React.MutableRefObject<VirtuosoHandle | null>;
  onReply: (data: {
    comment: FragmentType<typeof TrackCommentRowFragmentDoc>;
    userDisplayName: string;
    rootIndex: number;
  }) => void;
  withVaultTheme: boolean;
}) {
  const { loggedInUser } = useAuthContext();
  const asArtistId = loggedInUser?.artist?.id;
  const vaultContentId = content.id;

  const ownComments = useTrackCommentsOwnComments({ vaultContentId });

  const customPages = useMemo(() => {
    return orderBy(
      Array.from(ownComments?.values() || []).filter(v => {
        const commentNode = getFragment(
          TrackCommentRowFragmentDoc,
          v.data.vaultContentComments.edges[0]?.node,
        );
        return !commentNode?.replyTo;
      }),
      v => v.data.vaultContentComments.edges[0]?.node?.createdAt,
      'desc',
    );
  }, [ownComments]);

  const { orderedList, hasNextPage, loadMoreNextPage } = useInfiniteQuery(TrackCommentsDocument, {
    filterQueryKey: {
      vaultContentId,
    },
    customPages,
    variables({ pageParam }) {
      return {
        vaultContentId,
        asArtistId,
        after: pageParam?.after,
      };
    },
    onFetchCompleted(result) {
      /**
       * Update the "ownComments" store to always have the latest data available
       */
      const TrackCommentsOwnComments = getTrackCommentsOwnComments(vaultContentId);
      for (const fetchedComment of result.data.vaultContentComments.edges) {
        const ownCommentEdge = TrackCommentsOwnComments.get(fetchedComment.node.id)?.data
          .vaultContentComments.edges[0];
        if (ownCommentEdge) {
          ownCommentEdge.node = fetchedComment.node;
        }
      }
    },
    list(result) {
      return result.vaultContentComments.edges.map(edge => edge.node);
    },
    staleTime: 0,
    uniq(entity) {
      return entity.id;
    },
    getNextPageParam(result) {
      return (
        result.data.vaultContentComments.pageInfo.hasNextPage && {
          after: result.data.vaultContentComments.pageInfo.endCursor,
        }
      );
    },
  });

  const LoadingFooter = useCallback(
    () =>
      hasNextPage ? (
        <SkeletonComment
          isAuthor={false}
          className="rotate-180 scale-x-[-1] pt-3"
          withVaultTheme={withVaultTheme}
        />
      ) : (
        <View className="pt-3" />
      ),
    [hasNextPage, withVaultTheme],
  );

  const vaultArtistId = content.vault.artist?.id;
  const vaultId = content.vault.id;
  const trackId = content.id;

  const Header = useStableCallback(() => {
    return content.commentCaptionMessage ? (
      <>
        <TrackCommentRow
          rootIndex={0}
          comment={content.commentCaptionMessage}
          vaultArtistId={vaultArtistId}
          type="caption"
          className="w-full"
          contentId={trackId}
          vaultId={content.vault.id}
          trackDuration={content.duration}
          isFreeTierSubscription={isFreeTierSubscription}
          vaultArtistProfileImageUrl={content.vault.artist?.profileImage?.url ?? null}
          vaultArtistHandle={content.vault.artist?.linkValue}
          hasTrackCommentsWriteAccess={hasTrackCommentsWriteAccess}
          isDisabled={false}
          onReply={onReply}
          onHideReplies={() => {
            virtuosoRef.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
          folderId={content.parentVaultContentId}
        />
        <View className="py-2">
          <View
            className={twMerge(
              'flex h-[1px] w-full flex-row',
              withVaultTheme ? 'bg-vault_text/5' : 'bg-base700',
            )}
          />
        </View>
      </>
    ) : null;
  });

  const scrollerRef = useRef<HTMLElement | Window | null>(null);

  const handleScrollerRef = useCallback((ref: HTMLElement | Window | null) => {
    scrollerRef.current = ref;
  }, []);

  const commentContent = (i: number, message: (typeof orderedList)[number]) => {
    return (
      <TrackCommentRow
        key={message.id}
        rootIndex={i}
        comment={message}
        type="comment"
        vaultArtistId={vaultArtistId}
        contentId={trackId}
        vaultId={vaultId}
        trackDuration={content.duration}
        isFreeTierSubscription={isFreeTierSubscription}
        vaultArtistProfileImageUrl={content.vault.artist?.profileImage?.url ?? null}
        vaultArtistHandle={content.vault.artist?.linkValue}
        hasTrackCommentsWriteAccess={hasTrackCommentsWriteAccess}
        isDisabled={'optimistic' in message}
        onReply={onReply}
        onHideReplies={() => {
          virtuosoRef.current?.scrollToIndex({
            index: i,
            align: 'start',
            behavior: 'smooth',
          });
        }}
        folderId={content.parentVaultContentId}
      />
    );
  };

  const isScrolling = useRef(false);

  return (
    <Virtuoso
      data={orderedList}
      itemContent={commentContent}
      components={{
        Footer: LoadingFooter,
        Header: content.commentCaptionMessage ? Header : undefined,
      }}
      className={twMerge('h-full w-full', withVaultTheme ? 'scrollbar-theme' : 'scrollbar-dark')}
      ref={virtuosoRef}
      endReached={loadMoreNextPage}
      overscan={{ reverse: 100, main: 1000 }}
      scrollerRef={handleScrollerRef}
      isScrolling={scrolling => {
        isScrolling.current = scrolling;

        setIsScrolling(scrolling);
      }}
      computeItemKey={(_comment, item) => item.id}
    />
  );
}

function TrackCommentTimestampButton({
  onClick,
  isDisabled,
  withVaultTheme,
}: {
  onClick(data: { timestamp: TimestampString }): void;
  isDisabled: boolean;
  withVaultTheme: boolean;
}) {
  const { position } = useAudioPosition();

  const timestamp = useMemo(() => {
    return secondsToTimestamp(position);
  }, [position]);

  return (
    <Button
      label={timestamp}
      className={twMerge(
        'rounded-[100px] p-[4px_8px] font-base !text-base-s font-normal',
        withVaultTheme ? 'bg-vault_text/20 text-vault_text' : 'bg-base800 text-white',
      )}
      onClick={() => {
        onClick({ timestamp });
      }}
      disabled={isDisabled}
      trailingIcon={faPlusCircle}
    />
  );
}
