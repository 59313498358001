import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFuture, isPast, isToday } from 'date-fns';
import millify from 'millify';
import { Virtuoso } from 'react-virtuoso';
import { faSpinner, faWaveform } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BackButton } from '../../../components/buttons/BackButton';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { ErrorView } from '../../../components/error/ErrorView';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../../../components/loading/LoadingSkeleton';
import { EventItem, EventItemSkeleton } from '../../../components/membership/EventItem';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useQuery } from '../../../graphql/client';
import {
  type EventItemFragmentDoc,
  EventsSummaryDocument,
  type FragmentType,
  MembershipArtistDocument,
} from '../../../graphql/generated';
import { useArtistReleaseCampaigns } from '../../../hooks/membership/useArtistReleaseCampaigns';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../hooks/useVaultTheme';

gql(/* GraphQL */ `
  query EventsSummary($artistHandle: String!) {
    myEventsSummary(artistHandle: $artistHandle) {
      artistMembershipReceiptsCount
      newSubscriptionsFromEventsCount
    }
  }
`);

export const EventsPage = () => {
  useVaultTheme();
  const { artistHandle } = useArtistHandle();
  const {
    releaseCamapigns,
    hasNextPage,
    isLoadingNewPage,
    isInitialLoading,
    isError,
    loadMoreNextPage,
    refetch,
  } = useArtistReleaseCampaigns({
    artistHandle,
    source: 'events_page',
  });

  const {
    data: eventsSummary,
    isLoading: isLoadingEventsSummary,
    refetch: refetchEventsSummary,
  } = useQuery(EventsSummaryDocument, {
    variables: !!artistHandle && {
      artistHandle,
    },
    staleTime: 0,
    select: data => data.data.myEventsSummary,
  });

  const { loggedInUser } = useAuthContext();
  const isOwner =
    !!artistHandle &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  const renderItems = useStableCallback(
    (
      _i: number,
      eventData: FragmentType<EventItemFragmentDoc> & {
        id: string;
        createdAt: string | null;
      },
    ) => {
      const createdAt = eventData.createdAt;

      const isCurrentAndFuture = !!createdAt ? isFuture(createdAt) || isToday(createdAt) : false;

      if (!isCurrentAndFuture) {
        return (
          <div key={`${_i}-${eventData.id}-events_page`} className="flex h-20 w-full items-center ">
            <EventItem eventData={eventData} />
          </div>
        );
      }

      const nextDate = releaseCamapigns[_i + 1]?.createdAt ?? null;
      // past does not include today
      const isNextPast = nextDate ? (isToday(nextDate) ? false : isPast(nextDate)) : false;

      return (
        <React.Fragment key={`${_i}-${eventData.id}-events_page`}>
          <div className="flex h-20 w-full items-center ">
            <EventItem eventData={eventData} />
          </div>
          {isCurrentAndFuture && isNextPast && (
            <div className="relative flex items-center justify-center py-4">
              <hr className="h-[1px] w-full border-none bg-vault_text/20" />
              <span className="absolute bg-vault_background px-2 text-[14px]/[16px] text-vault_text/20">
                Past Events
              </span>
            </div>
          )}
        </React.Fragment>
      );
    },
  );

  const { data } = useQuery(MembershipArtistDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      artistHandle,
    },
    keepPreviousData: true,
  });

  const artistName = data?.data.artistLink?.artist?.name ?? artistHandle;

  const memoizedFooter = React.useCallback(
    () =>
      hasNextPage && isLoadingNewPage ? (
        <div className="flex w-full items-center justify-center py-5">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            className="ml-2 inline-block animate-spin rounded-full font-medium text-vault_text/50"
          />
        </div>
      ) : (
        <View className="h-4" />
      ),
    [hasNextPage, isLoadingNewPage],
  );

  const EmptyState = useCallback(() => {
    if (isInitialLoading) {
      return (
        <div className="flex flex-col">
          {Array.from({ length: 3 }).map((_, index) => (
            <EventItemSkeleton key={index} />
          ))}
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex h-[calc(100vh-400px)] w-full flex-col items-center justify-center gap-6 ">
          <ErrorView
            className="flex-grow"
            onRetryClick={() => {
              refetch();
              refetchEventsSummary();
            }}
            loggingType="events_page"
            withVaultTheme={false}
          />
        </div>
      );
    }

    return (
      <div className="flex h-[calc(100vh-400px)] w-full flex-col items-center justify-center gap-6 ">
        <FontAwesomeIcon icon={faWaveform} className="text-[40px] text-vault_text/60" />
        <p className="text-center text-[18px]/[24px] text-vault_text/60">
          {artistName}&nbsp;has no upcoming events.
          <br />
          Check back later!
        </p>
      </div>
    );
  }, [artistName, isError, isInitialLoading, refetch, refetchEventsSummary]);

  const Header = useCallback(() => {
    if (!isOwner) return null;

    if (isLoadingEventsSummary || !eventsSummary) {
      return (
        <View className="my-8 flex w-full flex-row items-center justify-start gap-2">
          <LoadingSkeleton
            className="flex h-[96.5px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text/10"
            withVaultTheme
          />
          <LoadingSkeleton
            className="flex h-[96.5px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text/10"
            withVaultTheme
          />
        </View>
      );
    }

    const receiptCount = eventsSummary.artistMembershipReceiptsCount;
    const newSubscriptionCount = eventsSummary.newSubscriptionsFromEventsCount;

    return (
      <View className="my-8 flex w-full flex-row items-center justify-start gap-2">
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(receiptCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total receipts</Text>
        </View>
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(newSubscriptionCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total signups</Text>
        </View>
      </View>
    );
  }, [eventsSummary, isLoadingEventsSummary, isOwner]);

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      withBottomNavigator={false}
      headerLeft={<BackButton className="text-vault_text" />}
      hasChatReadAccess={false}
      isHeaderTransparent={false}
      headerClassName="bg-vault_background md2:bg-vault_text/3"
      contentClassName="md2:bg-vault_text/3"
      vaultId=""
      messageChannelId=""
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Events</Text>
      }
      extend
    >
      <div className="box-border flex h-full w-full flex-col">
        <Virtuoso
          className="h-full w-full"
          useWindowScroll
          data={releaseCamapigns}
          itemContent={renderItems}
          components={{ Header, Footer: memoizedFooter, EmptyPlaceholder: EmptyState }}
          increaseViewportBy={700}
          endReached={loadMoreNextPage}
        />
      </div>
    </DefaultLayout>
  );
};
