import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { twMerge } from 'tailwind-merge';
import { faMobile } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { DateInput } from '../../screens/vault/announcement/Create';
import { CampaignHeaderSection } from '../../screens/vault/campaign/CreateCampaignPage';
import { formatSelectedDate, getTimezone } from '../announcement/helpers';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Toggle } from '../forms/Toggle';
import { CAMPAIGN_MESSAGE_MAX_CHARS, CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

export const ReleaseView = () => {
  const { setField, fields, errors, validateField } = useCampaignForm();
  const [announcementCharCounter, setAnnouncementCharCounter] = useState(
    fields.message?.length || 0,
  );
  const [releaseCharCounter, setReleaseCharCounter] = useState(fields.releaseMessage?.length || 0);

  const currDate = useMemo(() => new Date(), []);

  const filterPassedTime = useMemo(() => {
    return (time: Date) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);

      return currentDate.getTime() < selectedDate.getTime();
    };
  }, []);

  const isPresave = fields.campaignType === CampaignType.Presave;

  const timeZone = useMemo(() => {
    return getTimezone(currDate);
  }, [currDate]);

  const formatAnnouncementDate = fields.announcementDate
    ? formatSelectedDate(fields.announcementDate)
    : 'Select Date';

  const formatReleasetDate = fields.releaseDate ? formatSelectedDate(fields.releaseDate) : 'Now';

  return (
    <View className="flex h-full w-full flex-col">
      <CampaignHeaderSection
        icon={faMobile}
        title="Text members"
        description="Announce your song via SMS."
      />
      <View
        className={twMerge(
          'mb-8 mt-2 h-[1px] w-full bg-vault_text/10',
          fields.isAnnouncementDatePassed ? 'mb-4' : 'mb-8',
        )}
      />
      {fields.isAnnouncementDatePassed && (
        <Text className="mb-4 text-base-s text-destructive300">
          Announcement editing has been disabled since the messages have already been sent
        </Text>
      )}

      <View className="item mt-2 flex w-full flex-row items-center justify-between gap-2">
        <View className="flex flex-col">
          <Text
            className={twMerge(
              'font-title !text-title-s font-medium text-vault_text',
              (!fields.shouldSendSms || fields.isAnnouncementDatePassed) && 'opacity-50',
            )}
          >
            Send announcement message
          </Text>
          <Text className="mt-1 !text-base-m text-vault_text/50">
            {`This message will be sent out to notify fans to ${fields.campaignType === CampaignType.Presave ? 'presave' : 'stream'} the song.`}
          </Text>
        </View>
        <Toggle
          label=""
          onChange={e => {
            setField('shouldSendSms', e.target.checked);
            validateField('message');
          }}
          checked={fields.shouldSendSms}
          className="bg-acc w-20 text-vault_text"
          componentClassName={twMerge(
            'bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent',
            fields.isAnnouncementDatePassed && 'opacity-50',
          )}
          disabled={fields.isAnnouncementDatePassed}
        />
      </View>
      <textarea
        value={fields.message}
        name="message"
        aria-multiline="true"
        placeholder="Write your message here..."
        className={twMerge(
          'no-scrollbar mt-4 min-h-[200px] resize-none rounded-md border border-solid border-vault_text/10 bg-transparent p-3 font-base !text-base-l text-vault_text outline-none placeholder:text-vault_text/50',
          errors.message ? 'border-destructive300' : 'focus:border-vault_text',
          (!fields.shouldSendSms || fields.isAnnouncementDatePassed) && 'opacity-50',
        )}
        onChange={e => {
          setField('message', e.target.value);
          setAnnouncementCharCounter(e.target.value.length);
          validateField('message');
        }}
        onBlur={() => {
          validateField('message');
        }}
        disabled={!fields.shouldSendSms || fields.isAnnouncementDatePassed}
      />
      {fields.shouldSendSms && (
        <View className="flex flex-row items-center justify-between">
          <View className="flex flex-row items-center justify-center gap-1">
            <FontAwesomeIcon className="text-vault_text/50" icon={faLink} size="xs" />
            <Text className="text-base-s text-vault_text/50">
              Track link will be included in the message
            </Text>
          </View>
          <Text
            className={twMerge(
              'h-6 pt-2 font-base !text-base-s tabular-nums',
              !!errors.message ? 'text-destructive300' : 'text-vault_text/50',
            )}
          >
            {announcementCharCounter}/{CAMPAIGN_MESSAGE_MAX_CHARS}
          </Text>
        </View>
      )}

      <Text
        className={twMerge(
          'mt-6 font-title !text-title-s font-medium text-vault_text',
          (!fields.shouldSendSms || fields.isAnnouncementDatePassed) && 'opacity-50',
        )}
      >
        Date and time
      </Text>
      <DatePicker
        value={formatAnnouncementDate}
        selected={fields.announcementDate}
        onChange={date => {
          if (!date) {
            setField('announcementDate', currDate);
            return;
          }
          setField('announcementDate', date);
        }}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mm aa"
        className={twMerge(
          'mt-2 w-full rounded-md border border-solid border-vault_text/10 p-4 font-base !text-base-l text-vault_text outline-none',
          (!fields.shouldSendSms || fields.isAnnouncementDatePassed) && 'opacity-50',
        )}
        disabled={!fields.shouldSendSms || fields.isAnnouncementDatePassed}
        placeholderText="Now"
        filterTime={filterPassedTime}
        customInput={<DateInput isThemeEnabled />}
        minDate={currDate}
        timeIntervals={1}
      />

      <Text
        className={twMerge(
          'mb-4 h-6 pt-1 font-base !text-base-s tabular-nums text-vault_text/50',
          (!fields.shouldSendSms || fields.isAnnouncementDatePassed) && 'opacity-50',
        )}
      >
        {timeZone}
      </Text>

      {isPresave && (
        <View className="mb-8 mt-2">
          <View className="mb-8 h-[1px] w-full bg-vault_text/10" />
          {fields.isReleaseDatePassed && (
            <Text className="mb-4 text-base-s text-destructive300">
              Release announcement editing has been disabled since the messages have already been
              sent
            </Text>
          )}

          <View className="mt-2 flex w-full flex-row items-center justify-between gap-2">
            <View className="flex flex-col">
              <Text
                className={twMerge(
                  'font-title !text-title-s font-medium text-vault_text',
                  (!fields.shouldSendReleaseSms || fields.isReleaseDatePassed) && 'opacity-50',
                )}
              >
                Release day message
              </Text>
              <Text className="mt-1 !text-base-m text-vault_text/50">
                {`This message is sent on ${formatReleasetDate}`}
              </Text>
            </View>
            <Toggle
              label=""
              onChange={e => {
                setField('shouldSendReleaseSms', e.target.checked);
                validateField('releaseMessage');
              }}
              checked={fields.shouldSendReleaseSms}
              className="w-20 text-vault_text"
              componentClassName={twMerge(
                'bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent',
                fields.isReleaseDatePassed && 'opacity-50',
              )}
              disabled={fields.isReleaseDatePassed}
            />
          </View>
          <textarea
            value={fields.releaseMessage}
            name="release message"
            aria-multiline="true"
            placeholder="Write your message here..."
            className={twMerge(
              'no-scrollbar mt-4 box-border min-h-[200px] w-full resize-none rounded-md border border-solid border-vault_text/10 bg-transparent p-3 font-base !text-base-l text-vault_text outline-none placeholder:text-vault_text/50',
              errors.releaseMessage ? 'border-destructive300' : 'focus:border-vault_text',
              (!fields.shouldSendReleaseSms || fields.isReleaseDatePassed) && 'opacity-50',
            )}
            onChange={e => {
              setField('releaseMessage', e.target.value);
              setReleaseCharCounter(e.target.value.length);
              validateField('releaseMessage');
            }}
            onBlur={() => {
              validateField('message');
            }}
            disabled={!fields.shouldSendReleaseSms || fields.isReleaseDatePassed}
          />
          {fields.shouldSendReleaseSms && (
            <View className="flex flex-row items-center justify-between">
              <View className="flex flex-row items-center justify-center gap-1">
                <FontAwesomeIcon className="text-vault_text/50" icon={faLink} size="xs" />
                <Text className="text-base-s text-vault_text/50">
                  Link to the release page will be included in the message
                </Text>
              </View>
              <Text
                className={twMerge(
                  'h-6 pt-2 font-base !text-base-s tabular-nums',
                  !!errors.releaseMessage ? 'text-destructive300' : 'text-vault_text/50',
                )}
              >
                {releaseCharCounter}/{CAMPAIGN_MESSAGE_MAX_CHARS}
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};
