import { format, toZonedTime } from 'date-fns-tz';
import { ScheduledEventAccessFeatureInput } from '../../graphql/generated';
import { ONE_DAY_IN_MS } from './../../utils/dateUtils';
import {
  type AnnouncementErrors,
  AnnouncementFieldSchema,
  type AnnouncementStateFields,
} from './schema';
import { announcementState } from './store';

export const setField = <FieldKey extends keyof AnnouncementStateFields>(
  name: FieldKey,
  value: AnnouncementStateFields[FieldKey],
) => {
  announcementState.fields[name] = value;
};

export const setError = <FieldKey extends keyof AnnouncementStateFields>(
  name: FieldKey,
  value: string,
) => {
  announcementState.errors[name] = value;
};

export const clearErrors = () => {
  announcementState.errors = {
    announcement: null,
    accessType: null,
    date: null,
  };
};

export const clearFields = () => {
  announcementState.fields = {
    announcement: '',
    accessType: ScheduledEventAccessFeatureInput.FreeEvent,
    date: new Date(),
  };
};

export const validateField = async <FieldKey extends keyof AnnouncementErrors>(name: FieldKey) => {
  //clear field error
  announcementState.errors[name] = null;

  const result = await AnnouncementFieldSchema.shape[name].safeParseAsync(
    announcementState.fields[name],
  );

  if (!result.success) {
    announcementState.errors[name] = result.error.flatten().formErrors?.[0];
  }
};

export function setFieldsToUndefined(obj: object) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    if (typeof value === 'string' && value === '') {
      //@ts-expect-error  - overwrite with undefined
      result[key] = undefined;
    } else {
      //@ts-expect-error - this is fine
      result[key] = value;
    }
    return result;
  }, {});
}

export const getZonedDate = (date: Date) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return toZonedTime(date, localTimeZone);
};

export const getTimezone = (date: Date) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(toZonedTime(date, localTimeZone), 'z');
};

export const formatSelectedDate = (date: Date | null) => {
  const currDate = new Date();
  if (date == null || date.getTime() <= currDate.getTime()) {
    return 'Now';
  }

  //add day of the week
  return format(getZonedDate(date), 'eeee, MMM d, yyyy h:mm a');
};

export const getAudienceText = (accessType: ScheduledEventAccessFeatureInput) => {
  switch (accessType) {
    case ScheduledEventAccessFeatureInput.PaidEvent:
      return 'Paid members only';
    case ScheduledEventAccessFeatureInput.FreeEvent:
      return 'All members';

    default:
      return null;
  }
};

export const isWithinOneDayFromNow = (date: string) => {
  const currDate = new Date();
  const scheduledDate = new Date(date);

  const timeDiff = scheduledDate.getTime() - currDate.getTime();

  return timeDiff > 0 && timeDiff <= ONE_DAY_IN_MS;
};
