import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery } from '../../graphql/client';
import { SortDirection } from '../../graphql/generated';
import { ArtistCampaignsDocument } from '../../graphql/generated/documents';
import { ReleaseCampaignStatus } from './../../graphql/generated/types';

gql(/* GraphQL */ `
  query ArtistCampaigns(
    $artistHandle: String!
    $after: String
    $first: Int!
    $sort: SortDirection!
  ) {
    releaseCampaignsByArtistHandleByCreation(
      artistHandle: $artistHandle
      first: $first
      sort: $sort
      after: $after
    ) {
      edges {
        node {
          id
          status
          createdAt
          ...EventItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const useArtistReleaseCampaigns = ({
  artistHandle,
  limit = 15,
  sort = SortDirection.Desc,
  source,
}: {
  artistHandle?: string;
  limit?: number;
  sort?: SortDirection;
  source: 'events_page' | 'membership_page';
}) => {
  const { loggedInUser } = useAuthContext();

  const isOwner =
    (!!artistHandle &&
      loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle))) ??
    false;
  const {
    orderedList: releaseCamapigns,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  } = useInfiniteQuery(ArtistCampaignsDocument, {
    filterQueryKey: { artistHandle, source },
    staleTime: 0,
    enabled: !!artistHandle,
    getNextPageParam: ({ data }) => {
      return (
        data.releaseCampaignsByArtistHandleByCreation.pageInfo.hasNextPage && {
          after: data.releaseCampaignsByArtistHandleByCreation.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: limit,
          sort,
        };
      }),

    list: ({ releaseCampaignsByArtistHandleByCreation }) => {
      const nonDeletedCampaigns = releaseCampaignsByArtistHandleByCreation.edges
        .map(edge => edge.node)
        .filter(campaign => campaign.status !== ReleaseCampaignStatus.Deleted);

      if (isOwner) {
        return nonDeletedCampaigns;
      }
      return releaseCampaignsByArtistHandleByCreation.edges
        .map(edge => edge.node)
        .filter(campaign => campaign.status === ReleaseCampaignStatus.Active);
    },
    uniq: ({ id }) => id,
  });

  return {
    releaseCamapigns,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  };
};
