import { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import { captureException } from '@sentry/react';
import { Link } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faCancel, faEye, faEyeSlash, faLock } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsisH } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { isWithinOneDayFromNow } from '../../../components/announcement/helpers';
import { ArtistProfileImage } from '../../../components/artist/ArtistProfileImage';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { LinkifyText } from '../../../components/common/LinkifyText';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation, useQuery } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  AnnouncementRowFragmentDoc,
  ArtistByHandleDocument,
  getFragment,
  ScheduledEventStatus,
  ShowScheduledAnnouncementDocument,
} from '../../../graphql/generated';
import {
  CancelScheduledAnnouncementDocument,
  type FragmentType,
  GetPaginatedVaultAnnouncementsDocument,
  HideScheduledAnnouncementDocument,
} from '../../../graphql/generated';
import { usePaginatedVaultAnnouncements } from '../../../hooks/announcements/usePaginatedVaultAnnouncements';
import { useVaultAnnouncementEngagement } from '../../../hooks/announcements/useVaultAnnouncementEngagement';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useTimer } from '../../../hooks/useTimer';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { futureDateInterval, pastDateInterval } from '../../../utils/dateUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';

gql(/* GraphQL */ `
  fragment AnnouncementRow on Announcement {
    id
    content
    scheduledAt
    isFullVersionAvailable
    status
    featureAccess {
      feature {
        __typename
      }
    }
    vault {
      id
      artist: artistProfile {
        id
        name
        profileImage {
          id
          url
        }
      }
    }
  }

  mutation CancelScheduledAnnouncement($input: MutationDeleteAnnouncementInput!) {
    deleteAnnouncement(input: $input) {
      __typename
      ... on NotFoundError {
        message
      }
    }
  }

  mutation HideScheduledAnnouncement($input: MutationHideAnnouncementInput!) {
    hideAnnouncement(input: $input) {
      __typename
      ... on NotFoundError {
        message
      }
    }
  }

  mutation ShowScheduledAnnouncement($input: MutationShowAnnouncementInput!) {
    showAnnouncement(input: $input) {
      __typename
      ... on NotFoundError {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [
    CancelScheduledAnnouncementDocument,
    HideScheduledAnnouncementDocument,
    ShowScheduledAnnouncementDocument,
  ],
  refetch: [GetPaginatedVaultAnnouncementsDocument],
});

export function AnnouncementsPage() {
  const { artistHandle } = useArtistHandle();

  useVaultTheme();

  const {
    data,
    isLoading: isLoadingVaultId,
    isError: isErrorVaultId,
  } = useQuery(ArtistByHandleDocument, {
    staleTime: Infinity,
    variables: !!artistHandle && { link: artistHandle },
  });

  const {
    orderedList: announcements,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePaginatedVaultAnnouncements({
    enabled: !!data?.data.artistLink?.artist.linkValue,
    artistHandle:
      !!data?.data.artistLink?.artist.linkValue &&
      data?.data.artistLink.artist.linkValue.toLowerCase(),
  });

  const isOwner = !!data?.data.artistLink?.artist.mainVault?.isUserArtistAdmin;

  useVaultAnnouncementEngagement({
    vaultId: data?.data.artistLink?.artist.mainVaultId,
    triggerQuery:
      !isErrorVaultId && !isLoadingVaultId && data?.data.artistLink?.artist.mainVaultId != null,
  });

  const renderItem = useStableCallback(
    (_index: number, announcement: FragmentType<AnnouncementRowFragmentDoc>) => {
      return (
        !!artistHandle && (
          <AnnouncementRow
            announcement={announcement}
            isOwner={isOwner}
            artistHandle={artistHandle}
          />
        )
      );
    },
  );

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={<BackButton className="text-vault_text" />}
      headerRight={
        isOwner && (
          <Button
            label=""
            leadingIcon={faPlus}
            className="text-[20px] text-vault_text"
            href={artistNavigationPath(artistHandle, '/announcements/create')}
            iconOnly
          />
        )
      }
      stretch
      contentClassName="md2:bg-vault_text/3"
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Announcements</Text>
      }
      childrenWrapperClassName="px-0"
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
    >
      <Virtuoso
        itemContent={renderItem}
        data={announcements}
        className="no-scrollbar w-full flex-1"
        endReached={hasNextPage && !isFetchingNextPage ? () => fetchNextPage() : undefined}
      />
    </DefaultLayout>
  );
}

function AnnouncementRow({
  announcement,
  isOwner,
  artistHandle,
}: {
  announcement: FragmentType<AnnouncementRowFragmentDoc>;
  isOwner: boolean;
  artistHandle: string;
}) {
  const [isExpired, setIsExpired] = useState(false);
  const { openToast } = useToast();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const { mutateAsync: cancelScheduledAnnouncementAsync } = useMutation(
    CancelScheduledAnnouncementDocument,
    {},
  );

  const { mutateAsync: hideScheduledAnnouncementAsync } = useMutation(
    HideScheduledAnnouncementDocument,
    {},
  );

  const { mutateAsync: showScheduledAnnouncementAsync } = useMutation(
    ShowScheduledAnnouncementDocument,
    {},
  );

  const {
    id,
    content,
    scheduledAt,
    vault: { artist },
    isFullVersionAvailable,
    status,
    featureAccess,
  } = getFragment(AnnouncementRowFragmentDoc, announcement);

  const isFree = featureAccess.some(({ feature }) => feature.__typename === 'FreeScheduledEvent');

  const isActive = status === ScheduledEventStatus.Active;
  const isHidden = status === ScheduledEventStatus.Hidden;

  const alreadySent = useMemo(
    () => new Date(scheduledAt) < new Date() || isExpired,
    [isExpired, scheduledAt],
  );

  const needCountdown = useMemo(() => {
    if (alreadySent) {
      return false;
    }
    return isWithinOneDayFromNow(scheduledAt);
  }, [alreadySent, scheduledAt]);

  const dateString = useMemo(
    () =>
      alreadySent
        ? pastDateInterval(new Date(scheduledAt))
        : futureDateInterval(new Date(scheduledAt)),
    [alreadySent, scheduledAt],
  );

  const { hours, minutes, seconds } = useTimer({
    expiryTimestamp: new Date(scheduledAt),
    autoStart: needCountdown,
    onExpire: () => {
      setIsExpired(true);
    },
  });

  const onHideShowPress = useCallback(async () => {
    if (isHidden) {
      await showScheduledAnnouncementAsync({ input: { scheduledEventId: id } }).catch(e => {
        openToast({
          variant: 'error',
          text: 'Announcement could not be shown. Please try again later',
        });

        captureException(e, {
          extra: { scheduledEventId: id },
          tags: { source: 'AnnouncementRow', action: 'showScheduledAnnouncement' },
        });
      });
    } else {
      await hideScheduledAnnouncementAsync({ input: { scheduledEventId: id } }).catch(e => {
        openToast({
          variant: 'error',
          text: 'Announcement could not be hidden. Please try again later',
        });

        captureException(e, {
          extra: { scheduledEventId: id },
          tags: { source: 'AnnouncementRow', action: 'hideScheduledAnnouncement' },
        });
      });
    }

    closeBottomsheet();
  }, [
    closeBottomsheet,
    hideScheduledAnnouncementAsync,
    id,
    isHidden,
    openToast,
    showScheduledAnnouncementAsync,
  ]);

  const onCancelPress = useStableCallback(async () => {
    await cancelScheduledAnnouncementAsync({ input: { scheduledEventId: id } }).catch(e => {
      openToast({
        variant: 'error',
        text: 'Announcement could not be deleted. Please try again later',
      });

      captureException(e, {
        extra: { scheduledEventId: id },
        tags: { source: 'AnnouncementRow', action: 'cancelScheduledAnnouncement' },
      });
    });

    closeBottomsheet();
  });

  return (
    <View className="mx-4 flex flex-col gap-3 border-0 border-b border-solid border-vault_text/5 py-6">
      {artist != null && (
        <View className="flex flex-row">
          <ArtistProfileImage
            profileImageUrl={artist.profileImage?.url}
            className="h-8 w-8 rounded-full bg-vault_background"
          />
          <View className="ml-2">
            <Text className="line-clamp-1 font-base !text-base-m font-semibold text-vault_text">
              {artist.name}
            </Text>
            {isOwner &&
              (isActive ? (
                <Text className="flex font-base !text-base-s font-medium text-vault_text/50">
                  Sent to {isFree ? 'all members' : 'paid members'}
                  <Text className="ml-1 font-base !text-base-s font-medium text-vault_text">
                    • {dateString} ago
                  </Text>
                </Text>
              ) : isHidden ? (
                <Text className="flex font-base !text-base-s font-medium text-vault_text/50">
                  Hidden
                  <Text className="ml-1 font-base !text-base-s font-medium text-vault_text">
                    {alreadySent
                      ? `• ${dateString} ago`
                      : `• Scheduled ${
                          needCountdown
                            ? `in ${
                                hours > 0 ? `${hours}h ` : ''
                              }${minutes > 0 ? `${minutes}m ` : ''}${seconds > 0 ? `${seconds}s` : ''}`
                            : dateString
                        }`}
                  </Text>
                </Text>
              ) : (
                <Text className="font-base !text-base-s font-medium tabular-nums text-vault_text/50">
                  Scheduled{' '}
                  {needCountdown
                    ? `in ${
                        hours > 0 ? `${hours}h ` : ''
                      }${minutes > 0 ? `${minutes}m ` : ''}${seconds > 0 ? `${seconds}s` : ''}`
                    : dateString}
                </Text>
              ))}
          </View>
          {isOwner ? (
            <>
              <View className="flex-1" />
              <Popover.Root>
                <Popover.Trigger className="cursor-pointer appearance-none border-none bg-transparent p-1 text-vault_text outline-none transition-all duration-300 hover:text-vault_text/50">
                  <FontAwesomeIcon icon={faEllipsisH} fontSize={18} />
                </Popover.Trigger>

                <Popover.Portal>
                  <Popover.Content
                    align="end"
                    className="z-stickyHeader flex w-fit flex-col gap-1 rounded-lg bg-vault_text/10 backdrop-blur-2xl"
                  >
                    {alreadySent ? (
                      <Button
                        label={isHidden ? 'Show announcement' : 'Hide announcement'}
                        onClick={onHideShowPress}
                        leadingIcon={isHidden ? faEye : faEyeSlash}
                        type="secondary"
                        className="w-[248px] bg-transparent !text-base-m text-vault_text hover:bg-vault_text/20"
                      />
                    ) : (
                      <Button
                        label="Cancel scheduled message"
                        onClick={() => {
                          openBottomsheet({
                            type: 'CONFIRMATION',
                            confirmationBottomsheetProps: {
                              onConfirm: onCancelPress,
                              subText: 'Are you sure you want to cancel this announcement?',
                            },
                          });
                        }}
                        leadingIcon={faCancel}
                        type="secondary"
                        className="w-auto bg-transparent !text-base-m text-vault_text hover:bg-vault_text/20"
                      />
                    )}
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </>
          ) : (
            <Text className="ml-1 !text-base-m font-medium text-vault_text/50">· {dateString}</Text>
          )}
        </View>
      )}
      <View className="relative">
        <LinkifyText>
          <Text
            className={twMerge(
              'whitespace-pre-wrap break-words font-base text-[14px]/[20px] font-normal text-vault_text',
              !isFullVersionAvailable && 'blur-[6px]',
            )}
          >
            {content}
          </Text>
        </LinkifyText>
        {!isFullVersionAvailable && (
          <Link
            className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-1 no-underline"
            to={`/${artistHandle}/subscribe?artistHandle=${artistHandle}`}
          >
            <FontAwesomeIcon icon={faLock} className="text-[16px] text-vault_text" />
            <Text className="font-base text-[12px]/[18px] font-normal text-vault_text">
              Upgrade to see the announcement
            </Text>
          </Link>
        )}
      </View>
    </View>
  );
}
