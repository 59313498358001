import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { SpotifyContext } from '../contexts/SpotifyContext';
import { useSpotifyAccessToken } from '../hooks/spotify/useSpotifyAccessToken';
import type { SpotifyPlayerState, WebPlaybackError, WebPlaybackPlayer } from '../types/spotify';

export function SpotifyPlayerProvider({ children }: { children: ReactNode }) {
  const { accessToken } = useSpotifyAccessToken();
  const [activeCampaignId, setActiveCampaignId] = useState<string | null>(null);
  const [player, setPlayer] = useState<Window['Spotify']['Player'] | null>(null);
  const [playbackState, setPlaybackState] = useState<SpotifyPlayerState | null>(null);
  const [isPlaybackReady, setIsPlaybackReady] = useState(false);
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [error, setError] = useState<WebPlaybackError | null>(null);

  useEffect(() => {
    if (accessToken == null) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://sdk.scdn.co/spotify-player.js';
    script.async = true;

    document.body.appendChild(script);

    window.onSpotifyWebPlaybackSDKReady = () => {
      const player = new window.Spotify.Player({
        name: 'Vault',
        getOAuthToken: cb => {
          cb(accessToken);
        },
      });

      setPlayer(player);

      player.addListener('ready', ({ device_id }: WebPlaybackPlayer) => {
        setIsPlaybackReady(true);
        setDeviceId(device_id);
      });

      player.addListener('not_ready', () => {
        setIsPlaybackReady(false);
      });

      player.addListener('player_state_changed', (state: SpotifyPlayerState) => {
        setPlaybackState(state);
      });

      player.addListener('account_error', (error: WebPlaybackError) => {
        setError(error);
      });

      player.connect();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [accessToken]);

  useEffect(() => {
    if (player == null) {
      return;
    }

    const interval = setInterval(async () => {
      const state = await player.getCurrentState();
      setPlaybackState(state);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [player, playbackState]);

  const value = useMemo(
    () => ({
      isPlaybackReady,
      deviceId,
      player,
      playbackState,
      activeCampaignId,
      error,
      setActiveCampaignId,
    }),
    [activeCampaignId, deviceId, error, isPlaybackReady, playbackState, player],
  );

  return <SpotifyContext.Provider value={value}>{children}</SpotifyContext.Provider>;
}
