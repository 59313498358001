import { useEffect } from 'react';
import msFn from 'ms';
import { Navigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { isUUID4 } from '@soundxyz/utils/validation';
import {
  ClaimReceiptView,
  ClaimReceiptViewSkeleton,
} from '../../../components/campaign/ClaimReceiptView';
import { View } from '../../../components/common/View';
import { ErrorView } from '../../../components/error/ErrorView';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { useQuery } from '../../../graphql/client';
import {
  ArtistByHandleDocument,
  ReleaseCampaignByIdOrSlugDocument,
} from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useVaultTheme } from '../../../hooks/useVaultTheme';

const fiveMinutes = msFn('5 minutes');

export const ClaimReceiptPage = () => {
  const { value: presavesEnabled, isLoading: isPresavesEnabledLoading } = useGate(
    FEATURE_GATES.PRESAVES,
  );

  const { value: streamsEnabled, isLoading: isStreamsEnabledLoading } = useGate(
    FEATURE_GATES.STREAMS,
  );

  const { campaignSlugOrId } = useParams<{
    campaignSlugOrId: string;
  }>();

  const [searchParams] = useSearchParams();
  const step = searchParams.get('step');
  const source = searchParams.get('source');

  useVaultTheme();

  const { artistHandle } = useArtistHandle();

  const { setShowMenu } = useMenuContainer();

  const { data, isLoading, isError, refetch } = useQuery(ReleaseCampaignByIdOrSlugDocument, {
    staleTime: 0,
    variables: !!artistHandle &&
      !!campaignSlugOrId && {
        artistHandle: artistHandle.toLowerCase(),
        ...(isUUID4(campaignSlugOrId) ? { id: campaignSlugOrId } : { slug: campaignSlugOrId }),
      },
    filterQueryKey: {
      campaignSlugOrId,
      artistHandle,
    },
    // Refetch to always get latest campaign.currentState
    refetchInterval: fiveMinutes,
  });

  const {
    data: artistData,
    isLoading: isLoadingArtist,
    isError: isErrorArtist,
  } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && { link: artistHandle.toLowerCase() },
    select(data) {
      return data.data.artistLink;
    },
  });

  useEffect(() => {
    setShowMenu(false);

    return () => setShowMenu(true);
  }, [setShowMenu]);

  if (campaignSlugOrId == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError || isErrorArtist) {
    return (
      <View className="flex h-full w-full items-center justify-center bg-vault_background">
        <ErrorView onRetryClick={refetch} loggingType="claim_campaign_page" withVaultTheme />
      </View>
    );
  }

  if (isLoading || isStreamsEnabledLoading || isPresavesEnabledLoading || isLoadingArtist) {
    return <ClaimReceiptViewSkeleton />;
  }

  if (data?.data.releaseCampaignByIdOrSlug == null || !streamsEnabled || !presavesEnabled) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  // Presave Campaign require a source prop
  if (
    data.data.releaseCampaignByIdOrSlug.currentState === 'PRESAVE' &&
    source !== 'apple' &&
    source !== 'spotify'
  ) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <ClaimReceiptView
      campaignFrag={data.data.releaseCampaignByIdOrSlug}
      step={step}
      // Safe type casting cause we validate above
      sourceParam={source as 'apple' | 'spotify' | null}
      artistData={{
        membershipImage: artistData?.artist.membershipCardImage?.url ?? null,
        linkValue: artistData?.artist.linkValue,
        name: artistData?.artist.name,
      }}
    />
  );
};
