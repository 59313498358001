import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReleaseCampaignState } from '../../graphql/generated';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { getDSPColor, getDSPIcon } from './helpers';
import { CampaignType, type DSPInfo } from './schema';

export const DSPRow = ({
  disabled,
  dspInfo,
  isPreview,
  loading,
  onClick,
  type,
  campaignCurrentState,
}: {
  disabled: boolean;
  dspInfo: DSPInfo;
  isPreview: boolean;
  loading: boolean;
  onClick: (() => void) | undefined;
  type: CampaignType;
  campaignCurrentState: ReleaseCampaignState | undefined;
}) => {
  const icon = getDSPIcon(dspInfo.key);
  const color = getDSPColor(dspInfo.key);

  const isStreamingCampaign =
    type === CampaignType.Stream || campaignCurrentState === ReleaseCampaignState.Streaming;

  return (
    <View className="flex w-full flex-row justify-between">
      <View className="flex max-w-[55%] flex-row items-center gap-3">
        <FontAwesomeIcon className="h-6 w-6" icon={icon} color={color} />
        <Text className="line-clamp-1 text-base-l font-semibold text-white">{dspInfo.name}</Text>
      </View>

      <Button
        className="min-w-[92px] max-w-[50%] rounded-full bg-white/20 px-4 py-2.5 font-title text-[14px] font-medium text-white hover:bg-white/30"
        linkClassName="min-w-[92px] max-w-[50%]"
        label={dspInfo.buttonText}
        disabled={disabled}
        loading={loading}
        disabledClassName="opacity-50 hover:cursor-default hover:bg-white/20"
        labelClassName="line-clamp-1 break-all"
        onClick={
          isStreamingCampaign && !isPreview ? () => window.open(dspInfo.uri, '_blank') : onClick
        }
      />
    </View>
  );
};
