import { type LegacyRef, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import millify from 'millify';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { gql } from '@soundxyz/gql-string';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { FolderItemFragmentDoc, type FragmentType, getFragment } from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { formatDateString, pluralizeText } from '../../../utils/textUtils';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

gql(/* GraphQL */ `
  fragment FolderItem on VaultFolder {
    id
    title
    createdAt
    parentVaultContentId
    childrenVaultCount
    nestedChildrenVaultContentCount
    linkValue
    vault {
      id
      activeSubscription {
        id
        createdAt
      }
    }
  }
`);

export const FolderItem = ({
  folderInfo,
  isOwner,
  containerRef,
  onClick,
  editingFolder,
  hasEllipsis,
}: {
  folderInfo: FragmentType<FolderItemFragmentDoc>;
  isOwner: boolean;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
  onClick?: () => void;
  editingFolder: { id: string; title: string; isEditing: boolean } | null;
  hasEllipsis?: boolean;
}) => {
  const {
    id: folderId,
    title,
    createdAt,
    childrenVaultCount,
    vault: { activeSubscription },
  } = getFragment(FolderItemFragmentDoc, folderInfo);

  const { isVaultCustomizeOpen, setFolderId } = useMenuContainer();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: folderId });

  const isEditingFolder = editingFolder?.id === folderId;

  const couldBeNew =
    !isOwner && activeSubscription != null && activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || isVaultCustomizeOpen);
  }, [couldBeNew, isLoading, isVaultCustomizeOpen, isViewed]);

  return (
    <View
      className="flex w-full flex-grow cursor-pointer select-none flex-col gap-3 pb-3 text-left"
      containerRef={containerRef}
      onClick={() => {
        setFolderId(folderId);
        onClick?.();
      }}
    >
      <View className="relative flex min-h-[110px] w-full items-center justify-center rounded-md bg-vault_text/10 transition-all duration-300 ease-in-out group-hover:bg-vault_text/20">
        <AnimatePresence>
          {isNew && (
            <motion.div
              className="absolute right-3 top-2 rounded-full font-base !text-base-xs font-semibold text-vault_accent"
              exit={{ opacity: 0 }}
            >
              New
            </motion.div>
          )}
        </AnimatePresence>
        <View
          className={twMerge(
            'h-[74px] w-[76px] bg-contain bg-no-repeat',
            vaultTheme.mode === 'light'
              ? 'bg-folder-background-black'
              : 'bg-folder-background-white',
          )}
        />
      </View>
      <View className="flex flex-col gap-1">
        <View className="flex w-full justify-between gap-1">
          <Text className="line-clamp-2 text-ellipsis break-words font-base !text-base-m font-medium text-vault_text">
            {isEditingFolder ? editingFolder?.title : title ?? 'Untitled'}
          </Text>
          {!!hasEllipsis && <div className="h-5 w-5 flex-shrink-0" />}
        </View>
        <Text className="text-[12px] font-normal leading-[16px] text-vault_text/50">
          {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
          &nbsp;&middot;&nbsp;
          {millify(childrenVaultCount, { precision: 0 })}&nbsp;
          {pluralizeText({
            count: childrenVaultCount,
            text: 'file',
          })}
        </Text>
      </View>
    </View>
  );
};
