import { useEffect } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { useInView } from 'react-intersection-observer';
import { Navigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowDownArrowUp } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faGlobe, faMusic, faSave, faWaveform } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faReceipt } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Button } from '../../components/buttons/Button';
import { DropdownEllipsis } from '../../components/common/Dropdown';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { SkeletonUserRow, UserRow } from '../../components/user/UserRow';
import { EmptyStateView } from '../../components/views/EmptyStateView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { VaultSubscriptionStatus } from '../../graphql/generated';
import {
  getFragment,
  type GetMySubscribersQuery,
  TierTypename,
  UserRowFragmentDoc,
  VaultSubscriptionSourceType,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { filtersState, useMySubscribers } from '../../hooks/useMySubscribers';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { formatDateString } from '../../utils/textUtils';

const MySubscribersPage = ({ withVaultTheme = false }: { withVaultTheme?: boolean }) => {
  const { artistHandle } = useArtistHandle();
  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);
  const { loggedInUser, loginStatus } = useAuthContext();
  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });
  const { openBottomsheet } = useBottomsheetContainer();

  const isOwner =
    !!artistHandle &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  useVaultTheme({ enabled: withVaultTheme && !!artistHandle && isOwner });

  const { sort, status, tierLevel } = useSnapshot(filtersState);
  const hasFilterChanges = sort !== 'DESC' || !!status || !!tierLevel;

  const {
    hasNextPage,
    fetchNextPage,
    exportCsv,
    isError,
    subscribers,
    refetch,
    isInitialLoading,
    isLoadingError,
    isFetchingNextPage,
    isLoadingSummary,
    subscribersSummary,
  } = useMySubscribers();

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isAtBottom, fetchNextPage]);

  const onShareClick = () => {
    if (!loggedInUser?.artist?.mainLinkValue) return;

    const link = generateShareLink({
      artistLinkValue: loggedInUser.artist.mainLinkValue,
      inviteCode: loggedInUser.inviteCode,
      path: null,
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SHARE,
      shared: {
        withVaultTheme: false,
      },
      shareBottomsheetProps: {
        link,
        artistName: loggedInUser.artist?.name ?? 'vault',
        withVaultTheme: false,
      },
    });
  };

  const onExportCsvClick = () => {
    if (!loggedInUser?.artist?.mainVaultId) return;

    exportCsv({
      vaultId: loggedInUser.artist.mainVaultId,
    });
  };

  const buttons: ActionBottomsheetProps['buttons'] = [
    {
      leadingIcon: faArrowUpFromBracket,
      label: 'Export CSV',
      type: 'secondary',
      onClick: onExportCsvClick,
      event:
        loggedInUser?.artist?.id != null
          ? { type: EVENTS.EXPORT_CSV, properties: { artistId: loggedInUser.artist.id } }
          : undefined,
    },
  ];

  const onEllipsisClick = () => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ACTION,
      actionBottomsheetProps: {
        buttons,
        withVaultTheme: false,
      },
    });
  };

  if (!isOwner && !!artistHandle && loginStatus !== LoginStatus.LOADING) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  if (isError && subscribers.length === 0) {
    return (
      <SettingsLayout
        titleComponent={
          <Text
            className={twMerge(
              'font-title !text-title-m font-medium text-white',
              withVaultTheme && 'text-vault_text',
            )}
          >
            Members
          </Text>
        }
        withVaultTheme={withVaultTheme}
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="my_subscribers_page"
            withVaultTheme={withVaultTheme}
          />
        }
      />
    );
  }

  if (isInitialLoading) {
    return (
      <SettingsLayout
        withVaultTheme={withVaultTheme}
        titleComponent={
          <View className="flex flex-col items-center">
            <Text className={twMerge('font-title !text-title-m font-medium text-white')}>
              Members
            </Text>
            {!membershipV2Enabled && <LoadingSkeleton className="h-4 w-[100px]" />}
          </View>
        }
      >
        {membershipV2Enabled && (
          <View className="mb-6 mt-1 flex h-[98.5px] w-full flex-row items-center justify-start gap-2">
            <LoadingSkeleton
              className="flex h-full flex-1 flex-col rounded-md border border-solid border-vault_text border-opacity-10"
              withVaultTheme={withVaultTheme}
            />
            <LoadingSkeleton
              className="flex h-full flex-1 flex-col rounded-md border border-solid border-vault_text border-opacity-10"
              withVaultTheme={withVaultTheme}
            />
          </View>
        )}
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout
      withVaultTheme={withVaultTheme}
      titleComponent={
        <View className="flex flex-col items-center">
          <Text
            className={twMerge(
              'font-title !text-title-m font-medium text-white',
              withVaultTheme && 'text-vault_text',
            )}
          >
            Members
          </Text>
          {!membershipV2Enabled &&
            (isLoadingSummary ? (
              <LoadingSkeleton className="h-4 w-[100px]" withVaultTheme={withVaultTheme} />
            ) : (
              <Text
                className={twMerge(
                  'select-none overflow-x-hidden text-clip text-center font-base text-[12px]/[16px] font-normal',
                  withVaultTheme && 'text-vault_text/50',
                )}
              >
                {subscribersSummary?.data.vaultFromId?.type === 'FREEMIUM' ? (
                  <>
                    {subscribersSummary.data.mySubscribersSummary.activePaidSubscriptionsCount} paid
                    members •{' '}
                  </>
                ) : null}
                {subscribersSummary?.data.mySubscribersSummary.activeSubscriptionsCount ?? '...'}{' '}
                total members
              </Text>
            ))}
        </View>
      }
      nonScrollingChildren={
        !isInitialLoading &&
        loginStatus !== LoginStatus.LOADING &&
        subscribers.length === 0 && (
          <EmptyStateView
            title="You currently have no members"
            subtitle="Share your vault with fans to build up your members list"
            buttonText="Share"
            onButtonClick={onShareClick}
            withVaultTheme={withVaultTheme}
          />
        )
      }
      right={
        <DropdownEllipsis
          onClick={onEllipsisClick}
          buttons={buttons}
          dropdownType="Export Member CSV"
          withVaultTheme={withVaultTheme}
        />
      }
    >
      {membershipV2Enabled &&
        (subscribersSummary?.data.mySubscribersSummary != null ? (
          <View className="mb-6 mt-1 flex w-full flex-row items-center justify-start gap-2">
            <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
              <Text className="font-title text-[32px] font-medium text-vault_text">
                {millify(subscribersSummary.data.mySubscribersSummary.activeSubscriptionsCount, {
                  lowercase: true,
                })}
              </Text>
              <Text className="text-vault_text">Total members</Text>
            </View>
            <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
              <Text className="font-title text-[32px] font-medium text-vault_text">
                {millify(
                  subscribersSummary.data.mySubscribersSummary.activeSubscriptionsCountLast7Days,
                  { lowercase: true },
                )}
              </Text>
              <Text className="text-vault_text">New signups</Text>
            </View>
          </View>
        ) : (
          <View className="mb-6 mt-1 flex h-[98.5px] w-full flex-row items-center justify-start gap-2">
            <LoadingSkeleton
              className="flex h-full flex-1 flex-col rounded-md border border-solid border-vault_text border-opacity-10"
              withVaultTheme={withVaultTheme}
            />
            <LoadingSkeleton
              className="flex h-full flex-1 flex-col rounded-md border border-solid border-vault_text border-opacity-10"
              withVaultTheme={withVaultTheme}
            />
          </View>
        ))}
      <Button
        label="Sort & view"
        className={twMerge(
          'mb-4 flex items-center gap-2 self-start font-base !text-base-m font-medium text-base500',
          withVaultTheme && 'text-vault_text/60',
        )}
        leadingIcon={faArrowDownArrowUp}
        labelComponent={
          <View className="flex items-center gap-3">
            <Text>Sort & view</Text>

            {hasFilterChanges && (
              <View className="h-4 min-h-4 w-4 min-w-4 items-center justify-center rounded-full bg-yellow100 p-[1px]">
                <Text className="text-center font-base text-base-s text-black">1</Text>
              </View>
            )}
          </View>
        }
        onClick={() => {
          openBottomsheet({
            type: BOTTOMSHEET_TYPES.SUBSCRIBERS_FILTERS,
            shared: {
              withVaultTheme,
            },
            subscribersFiltersBottomsheetProps: {
              withVaultTheme,
            },
          });
        }}
      />
      <View className={twMerge('flex w-full flex-col', membershipV2Enabled && 'gap-6 pb-4')}>
        {subscribers.map(
          (
            {
              id,
              user,
              tier,
              status,
              createdAt,
              phone,
              email,
              joinedViaReferralCode,
              isTrial,
              userLocation,
              vaultSubscriptionSourceText,
              vaultSubscriptionSourceType,
              artistMembership,
            },
            i,
          ) => (
            <MyMemberRow
              key={user.id}
              id={id}
              user={user}
              tier={tier}
              status={status}
              createdAt={createdAt}
              phone={phone}
              email={email}
              joinedViaReferralCode={joinedViaReferralCode}
              isTrial={isTrial}
              userLocation={userLocation}
              vaultSubscriptionSourceText={vaultSubscriptionSourceText}
              vaultSubscriptionSourceType={vaultSubscriptionSourceType}
              artistMembership={artistMembership}
              bottomRef={i === subscribers.length - 1 ? bottomRef : undefined}
              withVaultTheme={withVaultTheme}
            />
          ),
        )}
      </View>
      {isFetchingNextPage ? (
        <>
          <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
          <SkeletonUserRow
            rightComponent
            className="mb-0 items-center rounded-none py-[20px]"
            withVaultTheme={withVaultTheme}
          />
          <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
          <SkeletonUserRow
            rightComponent
            className="mb-0 items-center rounded-none py-[20px]"
            withVaultTheme={withVaultTheme}
          />
          <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
          <SkeletonUserRow
            rightComponent
            className="mb-0 items-center rounded-none py-[20px]"
            withVaultTheme={withVaultTheme}
          />
        </>
      ) : isLoadingError ? (
        <ErrorView
          onRetryClick={fetchNextPage}
          loggingType="my_subscribers_page_next_page"
          withVaultTheme={withVaultTheme}
        />
      ) : null}
    </SettingsLayout>
  );
};

export { MySubscribersPage };

export function MyMemberRow({
  user,
  tier,
  status,
  createdAt,
  phone,
  email,
  joinedViaReferralCode,
  isTrial,
  userLocation,
  vaultSubscriptionSourceText,
  vaultSubscriptionSourceType,
  artistMembership,
  bottomRef,
  showRightComponent = true,
  withVaultTheme = false,
  className,
}: Omit<GetMySubscribersQuery['mySubscribers']['edges'][number]['node'], 'status' | 'tier'> & {
  id: string;
  bottomRef?: (node?: Element | null | undefined) => void;
  status?: VaultSubscriptionStatus;
  tier?: { __typename: TierTypename };
  showRightComponent?: boolean;
  withVaultTheme?: boolean;
  className?: string;
}) {
  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);
  const { loggedInUser } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();

  const onClick = useStableCallback(() => {
    const { id, avatar, username, displayName } = getFragment(UserRowFragmentDoc, user);

    openBottomsheet({
      type: 'USER_PROFILE',
      userProfileBottomsheetProps: {
        vaultId: loggedInUser?.artist?.mainVaultId,
        userId: id,
        vaultArtistId: loggedInUser?.artist?.id,
        avatarUrl: avatar?.url || '',
        username: username || '',
        displayName: displayName || '',
        phone,
        email,
        showAdminOptions: true,
        referralCodeInfo: joinedViaReferralCode ?? undefined,
        activeSubscriptionTier: tier?.__typename,
        userLocation,
        withVaultTheme,
        receipts: artistMembership?.receipts,
        vaultSubscriptionSourceText,
        vaultSubscriptionSourceType,
        joinDate: createdAt,
      },
    });
  });

  if (!membershipV2Enabled) {
    return (
      <UserRow
        key={user.id}
        user={user}
        phone={phone}
        subText={`${status === 'ACTIVE' ? 'Since' : 'Joined'} ${formatDateString({
          date: createdAt,
          format: 'month_year',
        })}`}
        className={twMerge(
          'mb-0 items-center rounded-none border-solid border-x-transparent border-b-transparent border-t-base800 py-[20px]',
          className,
        )}
        rightComponent={
          showRightComponent ? (
            isTrial ? (
              <Text className="font-base text-base-m font-medium text-blue400">Free Trial</Text>
            ) : status === 'INACTIVE' ? (
              <Text className="font-base text-base-m font-medium text-destructive300">
                Cancelled
              </Text>
            ) : tier?.__typename === TierTypename.FreeTier ? (
              <Text className="font-base text-base-m font-medium text-base500">Free Member</Text>
            ) : (
              <Text className="font-base text-base-m font-medium text-green300">Paid Member</Text>
            )
          ) : undefined
        }
        bottomRef={bottomRef}
        onClick={onClick}
        event={{
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: { bottomsheetType: BOTTOMSHEET_TYPES.USER_PROFILE, userId: user.id },
        }}
      />
    );
  }

  return (
    <UserRow
      key={user.id}
      className={twMerge(
        'mb-0 items-center gap-3 px-0 py-0 text-vault_text transition-all duration-200 ease-in hover:opacity-80',
        className,
      )}
      titleClassName={twMerge(withVaultTheme && 'text-vault_text')}
      user={user}
      phone={phone}
      subTextComponent={
        <Text className="mt-1 font-base text-base-m font-normal text-vault_text opacity-60">
          <FontAwesomeIcon
            icon={
              vaultSubscriptionSourceType === VaultSubscriptionSourceType.StreamingCampaignPage
                ? faWaveform
                : vaultSubscriptionSourceType === VaultSubscriptionSourceType.PresaveCampaignPage
                  ? faSave
                  : vaultSubscriptionSourceType === VaultSubscriptionSourceType.VaultContentPage
                    ? faMusic
                    : faGlobe
            }
          />{' '}
          {vaultSubscriptionSourceText ?? 'Profile signup'}
          <br />
          {formatDateString({
            date: createdAt,
            format: 'month_day_year',
          })}{' '}
          {status != null
            ? `• ${
                isTrial
                  ? `Free Trial`
                  : status === 'INACTIVE'
                    ? `Cancelled`
                    : tier?.__typename === TierTypename.FreeTier
                      ? `Free Member`
                      : `Paid Member`
              }`
            : ''}
        </Text>
      }
      profileImageClassName="w-[64px] h-[64px] rounded-full"
      withVaultTheme={withVaultTheme}
      rightComponent={
        artistMembership?.receipts != null ? (
          <View className="flex w-12 flex-row items-center justify-between gap-1 text-[14px] font-normal text-vault_text opacity-60">
            <FontAwesomeIcon icon={faReceipt} className="text-[14px]" />
            <View className="line-clamp-1 flex flex-shrink flex-row justify-end text-[14px]">
              <Text>{millify(artistMembership.receipts, { precision: 1, lowercase: true })}</Text>
            </View>
          </View>
        ) : undefined
      }
      onClick={onClick}
      event={{
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: { bottomsheetType: BOTTOMSHEET_TYPES.USER_PROFILE, userId: user.id },
      }}
    />
  );
}
