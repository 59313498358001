import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureMessage } from '@sentry/react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router';
import { useGate } from 'statsig-react';
import * as z from 'zod';
import { faLandscape, faTrash } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { deepTrim } from '@soundxyz/utils/src/string';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';

import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { UserProfileImage } from '../../components/user/UserProfileImage';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ToastContext } from '../../contexts/ToastContext';
import { GQLReactQuery, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  AuthUserDocument,
  type FragmentType,
  getFragment,
  UpdateUserDocument,
  UserProfileSettingsDocument,
  UserSettingsViewFragmentDoc,
} from '../../graphql/generated';
import { useEditProfile } from '../../hooks/useEditProfile';
import { useSingleLocationSelect } from '../../hooks/useSelectLocation';
import { useUploadAvatar } from '../../hooks/useUpdateAvatar';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getLocationText } from '../../utils/location';
import { sleep } from '../../utils/timerUtils';
import { removeInvalidUsernameChars, usernameSchema } from '../../utils/username';
import { EditProfileSkeleton, FormRow, SkeletonProfiePicture } from './EditArtistPage';

gql(/* GraphQL */ `
  fragment userSettingsView on PrivateUser {
    id
    username
    displayName
    email
    zipCode
    avatar {
      id
      url
      mediaType
    }
    userProvidedGoogleLocation {
      googlePlaceId
      city
      region
      country
    }
  }

  query UserProfileSettings {
    currentUser {
      __typename
      ... on QueryCurrentUserSuccess {
        data {
          id
          ...userSettingsView
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUserDocument],
  refetch: [AuthUserDocument, UserSettingsViewFragmentDoc],
});

function EditSubscriberProfilePage() {
  const { loginStatus } = useAuthContext();

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <EditSubscriberProfileLoader />;
}

export { EditSubscriberProfilePage };

function EditSubscriberProfileLoader() {
  const { data, isError, isLoading, refetch } = useQuery(UserProfileSettingsDocument, {
    staleTime: 0,
  });

  if (isError) {
    return (
      <SettingsLayout
        title="Edit profile"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="edit_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (isLoading || data == null) {
    return <SettingsLayout title="Edit profile" nonScrollingChildren={<EditProfileSkeleton />} />;
  }

  if (data.data.currentUser.__typename !== 'QueryCurrentUserSuccess') {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <EditSubscriberProfileForm data={data.data.currentUser.data} />;
}

const validationSchema = z.object({
  username: usernameSchema,
  displayName: z.string().max(50, { message: 'Max length 50 characters' }).nullish(),
  avatarImageMediaId: z.string().nullish(),
  email: z.string().email({ message: 'Invalid email address' }).nullish().or(z.literal('')),
  zipcode: z.string().nullish(),
  googlePlaceId: z.string().nullish(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

function EditSubscriberProfileForm({ data }: { data: FragmentType<UserSettingsViewFragmentDoc> }) {
  const { pathname } = useLocation();
  const { openToast } = ToastContext.useContainer();
  const userFragment = getFragment(UserSettingsViewFragmentDoc, data);
  const [isRemovingProfilePicture, setIsRemovingProfilePicture] = useState<boolean>(false);
  const [temporaryProfileImageUrl, setTemporaryProfileImageUrl] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    setValue,
    reset,
    setFocus,
  } = useForm<ValidationSchema>({
    defaultValues: {
      username: userFragment.username ?? '',
      displayName: userFragment.displayName ?? '',
      avatarImageMediaId: userFragment.avatar?.id ?? null,
      email: userFragment.email ?? undefined,
      zipcode: userFragment.zipCode ?? undefined,
      googlePlaceId: userFragment.userProvidedGoogleLocation?.googlePlaceId ?? null,
    },
    resolver: zodResolver(validationSchema),
  });

  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const { updateSubscriberProfile, updateSubscriberProfileRemoveFields } = useEditProfile();

  const { value: isGoogleLocationEnabled, isLoading: isGoogleLocationEnabledLoading } = useGate(
    FEATURE_GATES.USER_GOOGLE_LOCATION,
  );

  const onSubmit: SubmitHandler<ValidationSchema> = async props => {
    trackEvent({
      type: EVENTS.EDIT_PROFILE,
      properties: null,
      pathname,
    });

    const newEmail = props.email?.trim().toLowerCase() || null;
    const newZipCode = isGoogleLocationEnabled
      ? props.zipcode?.trim().toLowerCase() || null
      : undefined;

    const newDisplayName = deepTrim(props.displayName || '') || null;

    const removedDisplayName = !!userFragment.displayName && !newDisplayName;

    if (isRemovingProfilePicture || removedDisplayName) {
      await updateSubscriberProfileRemoveFields({
        input: {
          shouldRemoveAvatar: isRemovingProfilePicture,
          shouldRemoveDisplayName: removedDisplayName,
        },
      });
    }

    const result = await updateSubscriberProfile({
      input: {
        newUsername: props.username.toLowerCase().trim(),
        newDisplayName: deepTrim(props.displayName || '') || null,
        newAvatarId: props.avatarImageMediaId,
        newEmail,
        newZipCode,
        newLocationPlaceId: props.googlePlaceId,
      },
    });

    if (result.data.updateUser.__typename === 'MutationUpdateUserSuccess') {
      // Update the cache
      GQLReactQuery.setQueryData(
        {
          query: UserProfileSettingsDocument,
        },
        {
          data: {
            currentUser: {
              __typename: 'QueryCurrentUserSuccess',
              data: result.data.updateUser.data,
            },
          },
        },
      );

      // Update the form state
      const updatedFragment = getFragment(UserSettingsViewFragmentDoc, result.data.updateUser.data);
      reset({
        username: updatedFragment.username ?? '',
        displayName: updatedFragment.displayName ?? '',
        avatarImageMediaId: updatedFragment.avatar?.id ?? null,
        email: updatedFragment?.email ?? '',
        zipcode: updatedFragment?.zipCode ?? '',
      });
      setIsRemovingProfilePicture(false);
      setTemporaryProfileImageUrl(undefined);

      openToast({
        text: 'Your profile has been updated',
        variant: 'success',
      });
    } else if (result.data.updateUser.__typename === 'UsernameUnavailableError') {
      captureMessage('UsernameUnavailableError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          userFragment,
        },
        level: 'info',
      });
      openToast({
        text: 'Username is not available, please try a different username',
        variant: 'error',
      });
    } else if (result.data.updateUser.__typename === 'ValidationError') {
      captureMessage('ValidationError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          userFragment,
        },
        level: 'warning',
      });
      openToast({
        text: result.data.updateUser.message,
        variant: 'error',
      });
    } else {
      captureMessage('NotFoundError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          userFragment,
        },
        level: 'error',
      });
      openToast({
        text: 'Internal error, please try again later',
        variant: 'error',
      });
    }
  };

  const [updatePending, setUpdatePending] = useState<boolean>(false);
  const {
    getInputProps,
    getRootProps,
    inputRef,
    open: openAvatarUpload,
  } = useUploadAvatar({
    onConfirm: () => {
      setUpdatePending(true);
    },
    onDone: async () => {
      await sleep(400);
      setIsRemovingProfilePicture(false);
      setUpdatePending(false);
    },
    onSuccess({ mediaId, cdnUrl }) {
      setIsRemovingProfilePicture(false);
      setTemporaryProfileImageUrl(cdnUrl);
      setValue('avatarImageMediaId', mediaId, {
        shouldDirty: true,
      });
    },
  });

  const imageUri = isRemovingProfilePicture
    ? undefined
    : temporaryProfileImageUrl ?? userFragment.avatar?.url ?? undefined;

  const { locationSelect } = useSingleLocationSelect({
    initialLocation: userFragment.userProvidedGoogleLocation
      ? {
          placeId: userFragment.userProvidedGoogleLocation.googlePlaceId,
          label: getLocationText(userFragment.userProvidedGoogleLocation),
        }
      : null,
    placeholder: 'Search for a location',
    setIsKeyboardOpen: null,
    onLocationChange(value) {
      setValue('googlePlaceId', value?.value.place_id ?? null, {
        shouldDirty: true,
      });
    },
  });

  return (
    <SettingsLayout
      title="Edit my profile"
      right={
        <Button
          label="Save"
          className="font-title text-[16px]/[20px] font-medium text-yellow100"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || !isDirty || updatePending}
          disabledClassName="opacity-50"
        />
      }
    >
      <div className="cursor-pointer" {...getRootProps()} ref={inputRef}>
        {updatePending ? (
          <SkeletonProfiePicture />
        ) : (
          <UserProfileImage
            profileImageUrl={imageUri}
            className="mb-[12px] h-[100px] w-[100px]"
            withVaultTheme={false}
          />
        )}
        <input {...getInputProps()} />
      </div>
      <View className="flex flex-row">
        {!!(temporaryProfileImageUrl || isRemovingProfilePicture) && (
          <Button
            label="Reset"
            className="mr-[16px] font-title text-[16px]/[20px] font-medium text-yellow100"
            onClick={() => {
              setTemporaryProfileImageUrl(undefined);
              setIsRemovingProfilePicture(false);
              setValue('avatarImageMediaId', userFragment.avatar?.id ?? null, {
                shouldDirty: true,
              });
            }}
            disabled={isSubmitting}
            disabledClassName="opacity-50 cursor-default"
          />
        )}
        <Button
          label="Edit"
          className="font-title text-[16px]/[20px] font-medium text-yellow100"
          onClick={
            !updatePending
              ? () => {
                  if (imageUri != null) {
                    openBottomsheet({
                      type: BOTTOMSHEET_TYPES.ACTION,
                      actionBottomsheetProps: {
                        withVaultTheme: false,
                        buttons: [
                          {
                            label: 'Choose from library',
                            type: 'secondary',
                            leadingIcon: faLandscape,
                            onClick() {
                              closeBottomsheet();
                              openAvatarUpload();
                            },
                          },
                          {
                            label: 'Remove current picture',
                            type: 'secondary',
                            leadingIcon: faTrash,
                            leadingIconClassName: 'text-destructive300',
                            className: 'text-destructive300 rounded-xl rounded-t-none',
                            onClick() {
                              setIsRemovingProfilePicture(true);
                              setValue('avatarImageMediaId', null, {
                                shouldDirty: true,
                              });
                              closeBottomsheet();
                            },
                            position: 'bottom',
                          },
                        ],
                        className: 'h-fit pb-2',
                      },
                    });
                  } else {
                    openAvatarUpload();
                  }
                }
              : undefined
          }
          disabled={isSubmitting}
          disabledClassName="opacity-50 cursor-default"
        />
      </View>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <View className="mt-[20px] w-full items-start pr-[16px]">
          {/* Username */}
          <Text
            className="mb-[28px] cursor-pointer font-title text-[16px]/[20px] font-medium text-white"
            onClick={() => setFocus('username')}
          >
            Username
          </Text>
          <FormRow
            placeholder=""
            isSubmitting={isSubmitting}
            registerResult={register('username', {
              onChange(event) {
                setValue(
                  'username',
                  removeInvalidUsernameChars(event.target.value.toLowerCase().trim()),
                );
              },
            })}
            error={errors.username?.message}
            className="pb-0.5"
            autoCapitalize="none"
            setFocus={() => setFocus('username')}
          />

          {/* Display name */}
          <Text
            className="mb-[28px] mt-[20px] cursor-pointer font-title text-title-s font-medium text-white"
            onClick={() => setFocus('displayName')}
          >
            Display name
          </Text>
          <FormRow
            placeholder=""
            isSubmitting={isSubmitting}
            registerResult={register('displayName')}
            error={errors.displayName?.message}
            className="pb-0.5"
            setFocus={() => setFocus('displayName')}
          />

          {/* Email */}
          <Text className="mb-[28px] mt-[20px] cursor-pointer font-title text-title-s font-medium text-white">
            Email
          </Text>
          <FormRow
            placeholder=""
            isSubmitting={isSubmitting}
            registerResult={register('email')}
            error={errors.email?.message}
            className="pb-0.5"
            autoCapitalize="none"
            setFocus={() => setFocus('email')}
          />

          {/* Postal Code */}
          {!isGoogleLocationEnabledLoading && !isGoogleLocationEnabled && (
            <>
              <Text className="mb-[28px] mt-[20px] cursor-pointer font-title text-title-s font-medium text-white">
                Postal code
              </Text>
              <FormRow
                placeholder=""
                isSubmitting={isSubmitting}
                registerResult={register('zipcode')}
                error={errors.zipcode?.message}
                className="pb-0.5"
                autoCapitalize="none"
                setFocus={() => setFocus('zipcode')}
              />
            </>
          )}

          {/* Google Location */}
          {!isGoogleLocationEnabledLoading && isGoogleLocationEnabled && (
            <>
              <Text className="mb-[28px] mt-[20px] cursor-pointer font-title text-title-s font-medium text-white">
                Location
              </Text>
              {locationSelect}
            </>
          )}
        </View>
      </form>
    </SettingsLayout>
  );
}
