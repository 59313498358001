import { type HTMLInputTypeAttribute, type ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureMessage } from '@sentry/react';
import type { SubmitHandler, UseFormRegisterReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import * as z from 'zod';
import { faInstagram, faSpotify, faTiktok } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faGlobe } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { deepTrim } from '@soundxyz/utils/src/string';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { FormErrorIndicator } from '../../components/forms/FormErrorIndicator';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { UserProfileImage } from '../../components/user/UserProfileImage';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { ToastContext } from '../../contexts/ToastContext';
import { GQLReactQuery, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';

import {
  ArtistProfileSettingsDocument,
  ArtistSettingsViewFragmentDoc,
  AuthUserDocument,
  type FragmentType,
  getFragment,
  UpdateArtistDocument,
  VaultCustomizationDocument,
} from '../../graphql/generated';
import { useEditProfile } from '../../hooks/useEditProfile';
import { useUploadAvatar } from '../../hooks/useUpdateAvatar';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { sleep } from '../../utils/timerUtils';

gql(/* GraphQL */ `
  fragment artistSettingsView on Artist {
    id
    name
    description
    customWebsiteUrl
    spotifyUrl
    instagramHandle
    tiktokHandle
    profileImage {
      id
      url
      mediaType
    }
  }

  mutation UpdateArtist($input: MutationUpdateArtistProfileInput!) {
    updateArtistProfile(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationUpdateArtistProfileSuccess {
        data {
          ...artistSettingsView
        }
      }
    }
  }

  query ArtistProfileSettings($artistId: UUID!) {
    artistById(artistId: $artistId) {
      id
      ...artistSettingsView
    }
  }
`);

export function EditArtistProfilePage() {
  const { loginStatus } = useAuthContext();

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <EditArtistProfileLoader />;
}

export function EditProfileSkeleton() {
  const { isDesktop } = useWindow();
  return (
    <View
      className={twMerge(
        'z-above1 flex w-full flex-1 flex-col items-center text-white',
        !isDesktop && 'sm:w-[66%] md:w-[50%] lg:w-[33%]',
      )}
    >
      <SkeletonProfiePicture />
      <LoadingSkeleton className="mb-[20px] h-[30px] w-[60px]" />
      <View className="box-border flex w-full flex-1 flex-col bg-black px-[16px]">
        <LoadingSkeleton className="mb-[28px] h-[30px] w-[100px]" />
        <LoadingSkeleton className="mb-[20px] h-[30px] w-full" />
        <LoadingSkeleton className="mb-[28px] mt-[20px] h-[30px] w-[100px]" />
        <LoadingSkeleton className="mb-[20px] h-[30px] w-full" />
      </View>
    </View>
  );
}

export function SkeletonProfiePicture({ className }: { className?: string }) {
  return <LoadingSkeleton className={className ?? 'mb-[12px] h-[100px] w-[100px] rounded-full'} />;
}

function EditArtistProfileLoader() {
  const { loggedInUser } = useAuthContext();

  const { data, isError, isLoading, refetch } = useQuery(ArtistProfileSettingsDocument, {
    staleTime: 0,
    variables: !!loggedInUser?.artist?.id && { artistId: loggedInUser.artist.id },
  });

  if (isError) {
    return (
      <SettingsLayout
        title="Edit profile"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="edit_artist_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (isLoading || data == null) {
    return (
      <SettingsLayout title="Edit profile">
        <EditProfileSkeleton />
      </SettingsLayout>
    );
  }

  if (data.data.artistById == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <EditArtistProfileForm data={data.data.artistById} />;
}

const simpleHandleRefinement = (value: string) => {
  return !value.includes('/') || value === '';
};

const validationSchema = z.object({
  displayName: z.string().max(50, { message: 'Max length 50 characters' }),
  description: z.string(),
  customWebsiteUrl: z
    .pipeline(
      z.string().transform(value => {
        if (!value) return value;

        if (!value.startsWith('http')) {
          return `https://${value}`;
        }

        return value;
      }),
      z.string().url({ message: 'Invalid URL' }),
    )
    .nullish()
    .or(z.literal('')),
  spotifyUrl: z
    .string()
    .url({ message: 'Invalid Spotify URL' })
    .refine(value => value.startsWith('https://open.spotify.com/') || value === '', {
      message: 'Invalid Spotify URL',
    })
    .nullish()
    .or(z.literal('')),
  instagramHandle: z
    .string()
    .refine(simpleHandleRefinement, {
      message: 'Invalid handle',
    })
    .nullish(),
  tiktokHandle: z
    .string()
    .refine(simpleHandleRefinement, {
      message: 'Invalid handle',
    })
    .nullish(),
  profileImageMediaId: z.string().nullish(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

RefetchOnComplete({
  trigger: [UpdateArtistDocument],
  refetch: [ArtistProfileSettingsDocument, AuthUserDocument, VaultCustomizationDocument],
});

function EditArtistProfileForm({ data }: { data: FragmentType<ArtistSettingsViewFragmentDoc> }) {
  const { pathname } = useLocation();
  const { openToast } = ToastContext.useContainer();
  const artistFragment = getFragment(ArtistSettingsViewFragmentDoc, data);
  const [temporaryProfileImageUrl, setTemporaryProfileImageUrl] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    setValue,
    reset,
    setFocus,
  } = useForm<ValidationSchema>({
    defaultValues: {
      ...artistFragment,
      displayName: artistFragment.name,
      profileImageMediaId: artistFragment.profileImage?.id ?? null,
    },
    resolver: zodResolver(validationSchema),
  });

  const { updateArtistProfile } = useEditProfile();

  const onSubmit: SubmitHandler<ValidationSchema> = async props => {
    trackEvent({
      type: EVENTS.EDIT_ARTIST_PROFILE,
      properties: { artistId: artistFragment.id },
      pathname,
    });

    const result = await updateArtistProfile({
      input: {
        name: deepTrim(props.displayName),
        socials: {
          customWebsiteUrl: props.customWebsiteUrl?.trim() || null,
          spotifyUrl: props.spotifyUrl?.trim() || null,
          instagramHandle: props.instagramHandle?.trim() || null,
          tiktokHandle: props.tiktokHandle?.trim() || null,
        },
        profileImageId: props.profileImageMediaId ?? undefined,
        artistId: artistFragment.id,
        description: props.description.trim() ?? undefined,
      },
    });

    if (result.data.updateArtistProfile.__typename === 'MutationUpdateArtistProfileSuccess') {
      // Update the cache
      GQLReactQuery.setQueryData(
        {
          query: ArtistProfileSettingsDocument,
          variables: { artistId: artistFragment.id },
        },
        {
          data: {
            artistById: {
              id: artistFragment.id,
              ...result.data.updateArtistProfile.data,
            },
          },
        },
      );

      // Update the form state
      const updatedFragment = getFragment(
        ArtistSettingsViewFragmentDoc,
        result.data.updateArtistProfile.data,
      );
      reset({
        ...updatedFragment,
        displayName: updatedFragment.name,
        profileImageMediaId: updatedFragment.profileImage?.id ?? null,
        description: updatedFragment.description,
      });
      setTemporaryProfileImageUrl(undefined);

      openToast({
        text: 'Your profile has been updated',
        variant: 'success',
      });
    } else if (result.data.updateArtistProfile.__typename === 'UsernameUnavailableError') {
      captureMessage('UsernameUnavailableError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          artistFragment,
        },
        level: 'info',
      });
      openToast({
        text: 'Username is not available, please try a different username',
        variant: 'error',
      });
    } else if (result.data.updateArtistProfile.__typename === 'ValidationError') {
      captureMessage('ValidationError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          artistFragment,
        },
        level: 'warning',
      });
      openToast({
        text: result.data.updateArtistProfile.message,
        variant: 'error',
      });
    } else {
      captureMessage('NotFoundError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          artistFragment,
        },
        level: 'error',
      });
      openToast({
        text: 'Internal error, please try again later',
        variant: 'error',
      });
    }
  };

  const [updatePending, setUpdatePending] = useState<boolean>(false);
  const { inputRef, getRootProps, getInputProps } = useUploadAvatar({
    onConfirm: () => {
      setUpdatePending(true);
    },
    onDone: async () => {
      await sleep(400);
      setUpdatePending(false);
    },
    onSuccess({ mediaId, cdnUrl }) {
      setTemporaryProfileImageUrl(cdnUrl);
      setValue('profileImageMediaId', mediaId, {
        shouldDirty: true,
      });
    },
  });

  return (
    <SettingsLayout
      title="Edit artist profile"
      right={
        <Button
          label="Save"
          className="font-title text-[16px]/[20px] font-medium text-yellow100"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || !isDirty || updatePending}
          disabledClassName="opacity-50"
        />
      }
    >
      <div className="cursor-pointer" {...getRootProps()} ref={inputRef}>
        {updatePending ? (
          <SkeletonProfiePicture />
        ) : (
          <UserProfileImage
            profileImageUrl={
              temporaryProfileImageUrl ?? artistFragment.profileImage?.url ?? undefined
            }
            className="mb-[12px] h-[100px]"
            withVaultTheme={false}
          />
        )}

        <input {...getInputProps()} />
      </div>

      <View className="flex flex-row">
        {temporaryProfileImageUrl && (
          <Button
            label="Reset"
            className="mr-[16px] font-title text-[16px]/[20px] font-medium text-yellow100"
            onClick={() => {
              setTemporaryProfileImageUrl(undefined);
              setValue('profileImageMediaId', artistFragment.profileImage?.id ?? null, {
                shouldDirty: true,
              });
            }}
            disabled={isSubmitting || updatePending}
            disabledClassName="opacity-50 cursor-default"
          />
        )}
        <Button
          label="Edit"
          className="font-title text-[16px]/[20px] font-medium text-yellow100"
          onClick={
            !updatePending
              ? () => {
                  inputRef.current?.click();
                }
              : undefined
          }
          disabled={isSubmitting || updatePending}
          disabledClassName="opacity-50 cursor-default"
        />
      </View>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Text
          className="mb-[28px] mt-[20px] cursor-pointer font-title text-[16px]/[20px] font-medium text-white"
          onClick={() => {
            setFocus('displayName');
          }}
        >
          Name
        </Text>
        <FormRow
          placeholder=""
          isSubmitting={isSubmitting}
          registerResult={register('displayName')}
          error={errors.displayName?.message}
          className="pb-0.5"
          setFocus={() => setFocus('displayName')}
        />
        <Text
          className="mb-2 mt-1 cursor-pointer font-title text-title-s font-medium text-white"
          onClick={() => {
            setFocus('description');
          }}
        >
          Description
        </Text>
        <FormAreaRow
          placeholder=""
          isSubmitting={isSubmitting}
          registerResult={register('description')}
          error={errors.description?.message}
          className="pb-0.5"
          setFocus={() => setFocus('description')}
        />
        <View className="my-[20px] w-full items-start pr-[16px]">
          <Text className="mb-[28px] font-title text-title-s font-medium text-white">Links</Text>

          <FormRow
            icon={
              <FontAwesomeIcon icon={faGlobe} className="mr-[8px] text-[24px] text-neutral400" />
            }
            placeholder="https://your_website.com"
            isSubmitting={isSubmitting}
            registerResult={register('customWebsiteUrl')}
            error={errors.customWebsiteUrl?.message}
            autoCapitalize="none"
            setFocus={() => setFocus('customWebsiteUrl')}
          />

          <FormRow
            icon={
              <FontAwesomeIcon icon={faSpotify} className="mr-[8px] text-[24px] text-neutral400" />
            }
            placeholder="https://open.spotify.com/artist/"
            isSubmitting={isSubmitting}
            registerResult={register('spotifyUrl')}
            error={errors.spotifyUrl?.message}
            autoCapitalize="none"
            setFocus={() => setFocus('spotifyUrl')}
          />

          <FormRow
            icon={
              <FontAwesomeIcon
                icon={faInstagram}
                className="mr-[8px] text-[24px] text-neutral400"
              />
            }
            placeholder="@"
            isSubmitting={isSubmitting}
            registerResult={register('instagramHandle')}
            error={errors.instagramHandle?.message}
            autoCapitalize="none"
            setFocus={() => setFocus('instagramHandle')}
          />

          <FormRow
            icon={
              <FontAwesomeIcon icon={faTiktok} className="mr-[8px] text-[24px] text-neutral400" />
            }
            placeholder="@"
            isSubmitting={isSubmitting}
            registerResult={register('tiktokHandle')}
            error={errors.tiktokHandle?.message}
            autoCapitalize="none"
            setFocus={() => setFocus('tiktokHandle')}
          />
        </View>
      </form>
    </SettingsLayout>
  );
}

export function FormRow({
  isSubmitting,
  error,
  icon,
  placeholder,
  registerResult,
  className,
  autoCapitalize,
  type = 'text',
  setFocus,
}: {
  isSubmitting: boolean;
  error?: string;
  icon?: ReactNode;
  placeholder: string;
  registerResult: UseFormRegisterReturn;
  className?: string;
  autoCapitalize?: 'none' | 'off' | 'on' | 'sentences' | 'words' | 'characters';
  type?: HTMLInputTypeAttribute;
  setFocus: () => void;
}) {
  return (
    <View className={twMerge('w-full cursor-pointer', className)} onClick={setFocus}>
      <View className="mx-1 my-3 box-content flex flex-col">
        <View className={twMerge('mx-1 flex flex-row gap-4', isSubmitting && 'opacity-50')}>
          {icon}
          <input
            type={type}
            disabled={isSubmitting}
            className="flex-1 border-none bg-transparent font-base text-base-l font-normal text-base50 focus:border-none focus:outline-none"
            placeholder={placeholder}
            {...registerResult}
            autoCapitalize={autoCapitalize}
          />
          {error != null && <FormErrorIndicator />}
        </View>
        <View
          className={twMerge(
            'mt-3 h-[1px] w-full bg-base700',
            error != null && 'bg-destructive300',
          )}
        />
      </View>
      {error && (
        <Text className="mb-5 mt-3 text-center font-base text-base-m font-normal text-destructive300">
          {error}
        </Text>
      )}
    </View>
  );
}

export function FormAreaRow({
  isSubmitting,
  error,
  icon,
  placeholder,
  registerResult,
  className,
  autoCapitalize,
  setFocus,
}: {
  isSubmitting: boolean;
  error?: string;
  icon?: ReactNode;
  placeholder: string;
  registerResult: UseFormRegisterReturn;
  className?: string;
  autoCapitalize?: 'none' | 'off' | 'on' | 'sentences' | 'words' | 'characters';
  setFocus: () => void;
}) {
  return (
    <View className={twMerge('w-full cursor-pointer', className)} onClick={setFocus}>
      <View className="mx-1 my-3 box-content flex flex-col">
        <View className={twMerge('mx-1 flex flex-row gap-4', isSubmitting && 'opacity-50')}>
          {icon}
          <textarea
            disabled={isSubmitting}
            className="h-auto min-h-[60px] w-full flex-1 resize-none border-0 border-none bg-black bg-transparent font-base text-[16px]/[20px] font-normal text-base50 focus:border-none focus:font-normal focus:outline-none"
            placeholder={placeholder}
            {...registerResult}
            autoCapitalize={autoCapitalize}
          />
          {error != null && <FormErrorIndicator />}
        </View>
        <View
          className={twMerge(
            'mt-3 h-[1px] w-full bg-base700',
            error != null && 'bg-destructive300',
          )}
        />
      </View>
      {error && (
        <Text className="mb-5 mt-3 text-center font-base text-base-m font-normal text-destructive300">
          {error}
        </Text>
      )}
    </View>
  );
}
