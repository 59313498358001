import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useMySubscribers } from '../../hooks/useMySubscribers';
import { MyMemberRow } from '../../screens/settings/MySubscribersPage';

import { artistNavigationPath } from '../../utils/navigationUtils';
import { SkeletonUserRow } from '../user/UserRow';
import { Item } from './shared';

export function NewMembersSection() {
  const { subscribers: members, isInitialLoading } = useMySubscribers({ limit: 3, sort: 'DESC' });
  const { artistHandle } = useArtistHandle();

  const hasMembers = members.length > 0;

  const firstThreeMembers = useMemo(() => members.slice(0, 3), [members]);

  if (isInitialLoading) {
    return (
      <Item header="New events">
        {Array.from({ length: 3 }).map((_, index) => (
          <SkeletonUserRow
            key={index}
            profileImageClassName="w-[64px] h-[64px] rounded-full"
            withVaultTheme
          />
        ))}
      </Item>
    );
  }

  if (!hasMembers) {
    return null;
  }

  return (
    <Item
      header="New members"
      headerLink={
        <Link
          className="font-base text-base-m font-medium  text-vault_text/50 no-underline  transition-all duration-200 ease-in hover:text-vault_text/80"
          to={artistNavigationPath(artistHandle, '/members')}
        >
          See all
        </Link>
      }
    >
      {firstThreeMembers.map(
        ({
          id,
          user,
          tier,
          status,
          createdAt,
          phone,
          email,
          joinedViaReferralCode,
          isTrial,
          vaultSubscriptionSourceText,
          vaultSubscriptionSourceType,
          userLocation,
          artistMembership,
        }) => (
          <MyMemberRow
            key={user.id}
            id={id}
            user={user}
            tier={tier}
            status={status}
            createdAt={createdAt}
            phone={phone}
            email={email}
            joinedViaReferralCode={joinedViaReferralCode}
            isTrial={isTrial}
            userLocation={userLocation}
            vaultSubscriptionSourceText={vaultSubscriptionSourceText}
            vaultSubscriptionSourceType={vaultSubscriptionSourceType}
            artistMembership={artistMembership}
            withVaultTheme
          />
        ),
      )}
    </Item>
  );
}
