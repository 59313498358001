import type { FC } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faCircleUser } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { DEFAULT_AVATAR } from '../../constants/imageConstants';
import { Image } from '../common/Image';

type Props = {
  className?: string;
  profileImageUrl?: string | null;
  onClick?: () => void;
  withVaultTheme: boolean;
  fallbackColor?: string | null;
};

const UserProfileImage: FC<Props> = ({
  className,
  profileImageUrl,
  onClick,
  withVaultTheme,
  fallbackColor,
}) => {
  return withVaultTheme && !profileImageUrl ? (
    <FontAwesomeIcon
      icon={faCircleUser}
      className={twMerge(
        'aspect-square rounded-[50%]',
        withVaultTheme ? 'text-vault_text/25' : undefined,
        className,
      )}
    />
  ) : (
    <Image
      src={profileImageUrl ?? DEFAULT_AVATAR}
      alt="User avatar"
      className={twMerge('aspect-square rounded-[50%]', className)}
      onClick={onClick}
      style={!!fallbackColor ? { backgroundColor: fallbackColor } : undefined}
    />
  );
};

export { UserProfileImage };
