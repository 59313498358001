import type { FC, ReactNode } from 'react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { ArtistVaultRowFragmentDoc, type FragmentType, getFragment } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import type { EventObject, EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { useBatchedVaultMessageUnreadCount } from '../views/hooks/useVaultMessageUnreadCount';

gql(/* GraphQL */ `
  fragment artistVaultRow on Artist {
    id
    name
    profileImage {
      id
      url
      dominantColor
    }
  }
`);

type Props<Event extends EventType> = {
  artist: FragmentType<ArtistVaultRowFragmentDoc> | null;
  isSelected?: boolean;
  onClick?: () => void;
  subText?: string;
  subTextComponent?: ReactNode;
  href?: string;
  className?: string;
  actionComponent?: ReactNode;
  profileImageClassName?: string;
  containerRef?: (node?: Element | null | undefined) => void;
  isMyVault?: boolean;
  event?: EventObject<Event>;
  vaultId: string;
  showUnreadCount?: boolean;
};

const VaultRow = <Event extends EventType>({
  artist,
  isSelected = false,
  onClick,
  subText,
  href,
  className,
  actionComponent,
  subTextComponent,
  profileImageClassName,
  containerRef,
  isMyVault = false,
  event,
  vaultId,
  showUnreadCount = true,
}: Props<Event>) => {
  const { loginStatus } = useAuthContext();
  const { unreadCount } = useBatchedVaultMessageUnreadCount({
    enabled: loginStatus === LoginStatus.LOGGED_IN && showUnreadCount,
    vaultId,
    messageChannelId: null,
  });

  const { pathname } = useLocation();
  const { profileImage, name = 'Unnamed' } = getFragment(ArtistVaultRowFragmentDoc, artist) || {};
  return (
    <Container
      className={twMerge(
        'flex flex-row justify-between rounded-xl py-[12px] pl-[8px] text-[unset] no-underline',
        isSelected && 'bg-base800',
        className,
      )}
      href={href}
      onClick={
        event != null
          ? () => {
              trackEvent({ ...event, pathname });
              onClick?.();
            }
          : onClick
      }
      containerRef={containerRef}
    >
      <View className={twMerge('flex flex-row', subText == null && 'items-center')}>
        <div className="relative flex">
          <ArtistProfileImage
            profileImageUrl={profileImage?.url}
            className={twMerge('mr-[16px] w-[48px]', profileImageClassName)}
          />
          {showUnreadCount && unreadCount && unreadCount > 0 ? (
            <View className="absolute bottom-[0.125rem] right-[0.725rem] flex h-[16px] w-[16px] items-center justify-center rounded-full border-[2px] border-solid border-black bg-yellow100 font-base text-[10px] font-semibold text-black" />
          ) : null}
        </div>
        <View className="flex flex-col justify-center">
          <View className={twMerge('mb-[5px] flex flex-row', subText == null && 'mb-0')}>
            <Text className="text-title-s text-white">{name}</Text>
            {isMyVault && (
              <View className="ml-[8px] flex items-center justify-center rounded-full bg-base700 px-2 text-center text-[11px] font-bold text-base400">
                My Vault
              </View>
            )}
          </View>
          {subTextComponent != null
            ? subTextComponent
            : subText != null && (
                <Text className="font-base text-base-m font-normal text-base400">{subText}</Text>
              )}
        </View>
      </View>
      {actionComponent != null && actionComponent}
    </Container>
  );
};

const Container: FC<{
  href?: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  containerRef?: (node?: Element | null | undefined) => void;
}> = ({ href, children, onClick, className, containerRef }) => {
  if (href == null) {
    return (
      <View onClick={onClick} className={className} containerRef={containerRef}>
        {children}
      </View>
    );
  }
  return (
    <Link to={href} onClick={onClick} className={className}>
      {children}
    </Link>
  );
};

export { VaultRow };
