import { useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { LinksView } from '../../../components/campaign/LinksView';
import { MusicCampaignView } from '../../../components/campaign/MusicCampaignView';
import { ReleaseView } from '../../../components/campaign/ReleaseView';
import { SetupView } from '../../../components/campaign/SetupView';
import { getDSPName } from '../../../components/campaign/helpers';
import {
  CampaignLinksType,
  CampaignSteps,
  CampaignType,
} from '../../../components/campaign/schema';
import { useCampaignForm } from '../../../components/campaign/useCampaignForm';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useQuery } from '../../../graphql/client';
import { ThirdPartyPlatform } from '../../../graphql/generated';
import { ReleaseCampaignByIdDocument } from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { CampaignSkeleton } from './CreateCampaignPage';

gql(/* GraphQL */ `
  query ReleaseCampaignById($id: UUID) {
    releaseCampaignByIdOrSlug(id: $id) {
      __typename
      id

      ... on ReleaseCampaign {
        id
        announcement {
          id
          content
          scheduledAt
        }
        releaseAnnouncement {
          id
          content
        }
        appleMusicResourceId
        artist {
          id
          linkValue
          name
          profileImage {
            id
            url
          }
        }
        contentType
        coverImage {
          id
          url
        }
        createdAt
        description
        isrc
        upc
        showAppleMusicPresave
        showSpotifyPresave
        externalLinks {
          cta
          enabled
          id
          order
          platform
          releaseCampaignId
          url
        }
        initialReleaseImageUrl
        linkValue
        currentState
        releaseDate
        status
        title
      }
    }
  }
`);

export const EditCampaignPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { artistHandle } = useArtistHandle();
  const { openBottomsheet } = useBottomsheetContainer();

  const { value: presaveEnabled, isLoading: isLoadingPresaveGate } = useGate(
    FEATURE_GATES.PRESAVES,
  );
  const { value: streamsEnabled, isLoading: isLoadingStreamGate } = useGate(FEATURE_GATES.STREAMS);

  const { campaignId } = useParams();
  const { isArtist, loggedInUser } = useAuthContext();

  const step = searchParams.get('step') || CampaignSteps.Setup;
  const justViewing = searchParams.get('viewing') === 'true';

  const justViewingPreview = justViewing && step === CampaignSteps.Preview;

  const {
    fields,
    isSetupComplete,
    isLinksComplete,
    isReleaseComplete,
    releaseCampaignUpdateLoading,
    enableSubmit,
    clearErrors,
    clearFields,
    onUpdate,
    determineNextStep,
    determinePrevStep,
    populateFields,
  } = useCampaignForm();

  const { data, isLoading, isError } = useQuery(ReleaseCampaignByIdDocument, {
    variables: {
      id: campaignId,
    },
    staleTime: 0,
    enabled: isArtist,
    select(data) {
      return data.data.releaseCampaignByIdOrSlug;
    },
  });

  useVaultTheme();

  const currDate = useMemo(() => new Date(), []);

  const isAnnouncementDatePassed = useMemo(() => {
    return fields.announcementDate && fields.announcementDate < currDate;
  }, [fields.announcementDate, currDate]);

  const isReleaseDatePassed = useMemo(() => {
    if (!fields.releaseDate) return true;
    return fields.releaseDate && fields.releaseDate < currDate;
  }, [fields.releaseDate, currDate]);

  const onBackClick = useStableCallback(() => {
    const prevStep = determinePrevStep(step as CampaignSteps);

    if (justViewingPreview) {
      clearFields();
      clearErrors();
      navigate(-1);
      return;
    }

    if (prevStep) {
      setSearchParams({ step: prevStep });
    } else {
      openBottomsheet({
        type: 'EXIT_FLOW',
        exitFlowBottomsheetProps: {
          onConfirm: () => {
            navigate(artistNavigationPath(artistHandle, '/'));
            clearFields();
            clearErrors();
          },
        },
      });
    }
  });

  const handleNextClick = () => {
    const currentStep = step as CampaignSteps;
    const nextStep = justViewingPreview ? CampaignSteps.Setup : determineNextStep(currentStep);
    if (nextStep && nextStep !== step) {
      setSearchParams({ step: nextStep });
    } else if (currentStep === CampaignSteps.Preview && enableSubmit && campaignId) {
      onUpdate(campaignId);
    }
  };

  const buttonText = useMemo(() => {
    switch (step) {
      case CampaignSteps.Setup:
      case CampaignSteps.PresavePrereleaseLinks:
      case CampaignSteps.PresaveReleaseLinks:
      case CampaignSteps.StreamReleaseLinks:
        return 'Next';
      case CampaignSteps.Release:
        return 'Preview';
      case CampaignSteps.Preview:
        return justViewingPreview ? 'Edit event' : 'Update event';
      default:
        return null;
    }
  }, [justViewingPreview, step]);

  const isButtonDisabled = useMemo(() => {
    if (step === CampaignSteps.Setup) {
      return !isSetupComplete;
    }
    if (
      step === CampaignSteps.PresavePrereleaseLinks ||
      step === CampaignSteps.PresaveReleaseLinks ||
      step === CampaignSteps.StreamReleaseLinks
    ) {
      return !isLinksComplete;
    }
    if (step === CampaignSteps.Release) {
      return !isReleaseComplete;
    }
    return !enableSubmit;
  }, [enableSubmit, isLinksComplete, isReleaseComplete, isSetupComplete, step]);

  const content = useMemo(() => {
    switch (step) {
      case CampaignSteps.Setup:
        return <SetupView artistId={data?.artist.id} />;
      case CampaignSteps.PresavePrereleaseLinks:
        return <LinksView type={CampaignLinksType.PresavePrereleaseLinks} />;
      case CampaignSteps.PresaveReleaseLinks:
        return <LinksView type={CampaignLinksType.PresaveReleaseLinks} />;
      case CampaignSteps.StreamReleaseLinks:
        return <LinksView type={CampaignLinksType.StreamReleaseLinks} />;
      case CampaignSteps.Release:
        return <ReleaseView />;
      case CampaignSteps.Preview:
        return <MusicCampaignView fields={fields} isPreview />;
      default:
        return <Navigate to={ROUTES.NOT_FOUND} />;
    }
  }, [data?.artist.id, fields, step]);

  const ctaButton = (
    <>
      {step !== CampaignSteps.Scan && buttonText && (
        <View
          className={twMerge(
            'sticky z-mobileNav flex h-[68px] max-h-[68px] w-full items-center justify-center border-0 border-t border-solid py-2',
            'border-t-vault_text/5 bg-vault_background',
          )}
        >
          <Button
            type="primary-themed"
            label={buttonText}
            className="mx-4 w-full self-center"
            loading={releaseCampaignUpdateLoading}
            disabled={isButtonDisabled}
            disabledClassName="opacity-50 cursor-not-allowed"
            onClick={handleNextClick}
          />
        </View>
      )}
    </>
  );

  const mappedDsps = useMemo(() => {
    if (!data) return [];

    const { externalLinks, currentState, releaseDate } = data;
    const campaignType =
      currentState === 'PRESAVE' && releaseDate && new Date(releaseDate) > currDate
        ? CampaignType.Presave
        : CampaignType.Stream;
    const isPresave = campaignType === CampaignType.Presave;

    const defaultDsps = [
      {
        key: ThirdPartyPlatform.Spotify,
        name: getDSPName(ThirdPartyPlatform.Spotify),
        uri: '',
        buttonText: 'Pre-save',
        showLink: true,
      },
      {
        key: ThirdPartyPlatform.AppleMusic,
        name: getDSPName(ThirdPartyPlatform.AppleMusic),
        uri: '',
        buttonText: 'Pre-add',
        showLink: true,
      },
    ];

    const existingDsps = externalLinks.map(
      (link: { platform: string; url: string; cta: string | null; enabled: boolean }) => ({
        key: link.platform as ThirdPartyPlatform,
        name: getDSPName(link.platform as ThirdPartyPlatform),
        uri: link.url,
        buttonText: link.cta || '',
        showLink: link.enabled,
      }),
    );

    if (isPresave) {
      const mergedDsps = [...existingDsps];
      defaultDsps.forEach(defaultDsp => {
        if (!mergedDsps.some(dsp => dsp.key === defaultDsp.key)) {
          mergedDsps.push(defaultDsp);
        }
      });
      return mergedDsps;
    } else {
      return existingDsps;
    }
  }, [data, currDate]);

  useEffect(() => {
    if (isLoadingPresaveGate || isLoadingStreamGate) return;

    if (
      (data?.artist.id && loggedInUser?.artist?.id && data.artist.id !== loggedInUser.artist.id) ||
      (data?.currentState === 'PRESAVE' && !presaveEnabled) ||
      (data?.currentState === 'STREAMING' && !streamsEnabled)
    ) {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  }, [
    artistHandle,
    data,
    isLoadingPresaveGate,
    isLoadingStreamGate,
    loggedInUser?.artist?.id,
    navigate,
    presaveEnabled,
    streamsEnabled,
    data?.currentState,
  ]);

  useEffect(() => {
    if (!data) return;

    const {
      currentState,
      coverImage,
      initialReleaseImageUrl,
      title,
      artist,
      releaseDate,
      announcement,
      releaseAnnouncement,
      contentType,
      description,
      isrc,
      upc,
      showAppleMusicPresave,
      showSpotifyPresave,
    } = data;

    const campaignType =
      currentState === 'PRESAVE' && releaseDate && new Date(releaseDate) > currDate
        ? CampaignType.Presave
        : CampaignType.Stream;

    populateFields({
      isEditMode: true,
      campaignType,
      image: coverImage?.url || '',
      mediaId: coverImage?.id || null,
      thirdPartyImageUrl: initialReleaseImageUrl || null,
      title,
      description: description || '',
      isrc,
      upc,
      artist: artist.name,
      releaseDate: releaseDate ? new Date(releaseDate) : new Date(),
      announcementDate: announcement?.scheduledAt ? new Date(announcement.scheduledAt) : new Date(),
      contentType,
      message: announcement?.content || '',
      shouldSendSms: !!announcement?.content,
      releaseMessage: releaseAnnouncement?.content || '',
      shouldSendReleaseSms: !!releaseAnnouncement?.content,
      dsps: mappedDsps,
      isAnnouncementDatePassed,
      isReleaseDatePassed,
      showAppleMusicPresave,
      showSpotifyPresave,
    });
  }, [currDate, data, isAnnouncementDatePassed, isReleaseDatePassed, populateFields, mappedDsps]);

  if (isLoading || data == null) {
    return <CampaignSkeleton onBackClick={onBackClick} />;
  }

  if (!isArtist || isError) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={
        <BackButton
          onClick={onBackClick}
          className={step !== CampaignSteps.Preview ? 'text-vault_text' : undefined}
        />
      }
      headerCenter={
        <>
          {step === CampaignSteps.Preview && (
            <Text
              className={twMerge(
                'font-title !text-title-m font-medium',
                step !== CampaignSteps.Preview ? 'text-vault_text' : 'text-white',
              )}
            >
              Event preview
            </Text>
          )}
        </>
      }
      shouldSkipMargin
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      customBottomNavigator={<View className="w-full md2:hidden">{ctaButton}</View>}
      headerClassName="grow-0"
      contentClassName={twMerge(
        'flex-1',
        step !== CampaignSteps.Preview ? 'md2:bg-vault_text/3' : 'bg-black',
      )}
      extend={step === CampaignSteps.Preview}
      footer={<View className="hidden w-full md2:block">{ctaButton}</View>}
    >
      <View className="flex h-full w-full flex-1 flex-col md2:pt-4">
        <View
          className={step !== CampaignSteps.Preview ? 'mx-4' : 'relative box-border overflow-clip'}
        >
          {content}
        </View>
      </View>
    </DefaultLayout>
  );
};
