import { useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { Navigate } from 'react-router';
import { MessageChannelLayout } from '../../components/layouts/MessageChannelLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { LockedChatView } from '../../components/views/LockedChatView';
import {
  LoadingChat,
  VaultMessageChannelView,
} from '../../components/views/VaultMessageChannelView';
import { useVaultMessageCountEngagement } from '../../components/views/hooks/useVaultMessageCountEngagement';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';

import {
  ArtistByHandleDocument,
  ArtistLayoutFragmentDoc,
  FeatureTypename,
  getFragment,
  TierTypename,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../hooks/useUpsellInterstitials';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';

export const VaultMessageChannelPage = () => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  useVaultTheme();

  const { openBottomsheet } = useBottomsheetContainer();

  const { data: artist, isLoading } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && { link: artistHandle.toLowerCase() },
    enabled: artistHandle != null,
  });

  useVaultMessageCountEngagement({
    vaultId: artist?.data.artistLink?.artist.mainVaultId,
    triggerQuery: loggedInUser != null,
  });

  const artistLayoutFragment =
    artist?.data.artistLink?.artist != null
      ? getFragment(ArtistLayoutFragmentDoc, artist.data.artistLink.artist)
      : null;

  useSetMetaHeaders({
    title: artistLayoutFragment ? `${artistLayoutFragment.name}'s Vault Chat` : null,
    imageUrl: artistLayoutFragment?.profileImage?.url,
  });

  const isOwner =
    loggedInUser?.artist?.id != null &&
    loggedInUser?.artist?.id === artistLayoutFragment?.mainVault?.artist?.id;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: artistLayoutFragment?.mainVault.activeSubscription,
    isOwner,
  });

  const showUpsellInterstitial =
    upsellInterstitials?.firstChat === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession &&
    !activeSubscriptionFeatures?.enabledFeatures.ChatRead;

  useEffect(() => {
    if (showUpsellInterstitial) {
      setTimeout(() => {
        const vaultId = artist?.data.artistLink?.artist.mainVaultId ?? null;

        openBottomsheet({
          type: 'GET_APP',
          getAppBottomsheetProps: {
            vaultId,
            interstitial: 'first_chat',
            onContinue: null,
          },
        });

        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'GET_APP',
            vaultId,
            interstitial: 'first_chat',
          },
        });
      }, 2000);
    }
  }, [
    artist?.data.artistLink?.artist.mainVaultId,
    loginStatus,
    openBottomsheet,
    showUpsellInterstitial,
    upsellInterstitials,
    wasShowedInSession,
  ]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const vaultArtistName = artistLayoutFragment?.name;
  const vaultContentCount = artistLayoutFragment?.mainVault?.contentCount;
  const vaultArtistProfileImage = artistLayoutFragment?.profileImage?.url;
  const vaultArtistMembershipCardImage = artistLayoutFragment?.membershipCardImage?.url;

  const showLockedChatView = !activeSubscriptionFeatures?.enabledFeatures.ChatRead && !isOwner;

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const chatAvailableForFreeUsers = artistLayoutFragment?.mainVault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  if (loginStatus === LoginStatus.LOADING || isLoading) {
    return (
      <LoadingChat
        vaultArtistName={vaultArtistName}
        vaultArtistImage={vaultArtistProfileImage}
        activeSubscriptionFeatures={activeSubscriptionFeatures}
        messageChannelId={artistLayoutFragment?.mainVault.messageChannelId}
        hasChatReadAccess={hasChatReadAccess}
        chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
      />
    );
  }

  return (
    <>
      {showLockedChatView ? (
        <MessageChannelLayout
          artistName={vaultArtistName}
          stretch
          artistProfileImage={vaultArtistProfileImage}
          activeSubscriptionFeatures={activeSubscriptionFeatures}
          messageChannelId={artistLayoutFragment?.mainVault.messageChannelId}
          hasChatReadAccess={hasChatReadAccess}
          chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
        >
          <LockedChatView
            vaultId={artist?.data.artistLink?.artist.mainVault.id}
            vaultType={artist?.data.artistLink?.artist.mainVault.type}
            linkValue={artistLayoutFragment?.linkValue}
            vaultArtistName={vaultArtistName}
            vaultArtistImage={vaultArtistProfileImage}
            vaultArtistMembershipCardImage={vaultArtistMembershipCardImage}
            monthlySubPrice={artistLayoutFragment?.mainVault.price || DEFAULT_PRICE}
            chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
          />
        </MessageChannelLayout>
      ) : (
        <VaultMessageChannelView
          artistName={vaultArtistName}
          contentCount={vaultContentCount}
          isOwner={isOwner}
          artistProfileImage={vaultArtistProfileImage}
          activeSubscriptionFeatures={activeSubscriptionFeatures}
        />
      )}
    </>
  );
};
