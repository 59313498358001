import { captureException } from '@sentry/react';
import { gql } from '@soundxyz/gql-string';
import { useToast } from '../contexts/ToastContext';
import { useMutation } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistByHandleDocument,
  ArtistMainVaultViewFragmentDoc,
  GetActiveVaultsSubscriptionsDocument,
  GetArtistInvitesPageDocument,
  GetArtistsToExploreDocument,
  GetPriorityActiveVaultSubscriptionsDocument,
  SubscribeFreeTierDocument,
  TopVaultInvitersDocument,
  UserArtistMembershipDocument,
  UserArtistReceiptsDocument,
  UserVaultInviteStatsDocument,
  VaultContentByFolderDocument,
  VaultContentByFolderPositionDocument,
  VaultContentByIdDocument,
  VaultContentBySlugDocument,
  VaultContentPaginationDocument,
} from '../graphql/generated';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';

gql(/* GraphQL */ `
  mutation SubscribeFreeTier($input: MutationSubscribeToVaultFreeInput!) {
    subscribeToVaultFree(input: $input) {
      __typename
      ... on MutationSubscribeToVaultFreeSuccess {
        data {
          id
          status
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [SubscribeFreeTierDocument],
  refetch: [
    ArtistByHandleDocument,
    VaultContentPaginationDocument,
    ArtistMainVaultViewFragmentDoc,
    GetActiveVaultsSubscriptionsDocument,
    GetArtistsToExploreDocument,
    GetPriorityActiveVaultSubscriptionsDocument,
    GetArtistInvitesPageDocument,
    UserVaultInviteStatsDocument,
    TopVaultInvitersDocument,
    VaultContentByFolderDocument,
    VaultContentByFolderPositionDocument,
    VaultContentByIdDocument,
    VaultContentBySlugDocument,
    UserArtistMembershipDocument,
    UserArtistReceiptsDocument,
  ],
  throttled: '500ms',
});

export function useFreeTier() {
  const { openToast } = useToast();

  const { mutateAsync: subscribeFreeTier, isLoading: isSubscribingFreeTier } = useMutation(
    SubscribeFreeTierDocument,
    {
      retry: 3,
      onSuccess: data => {
        if (data.data.subscribeToVaultFree.__typename === 'MutationSubscribeToVaultFreeSuccess') {
          trackEvent({
            type: EVENTS.SUBSCRIBE_FREE_TIER_SUCCESS,
            properties: null,
          });
          openToast({
            text: 'You have successfully subscribed to this vault!',
            variant: 'success',
          });
        } else {
          openToast({
            text: 'An error occured subscribing to the free tier. Please try again.',
            variant: 'error',
          });
        }
      },
      onError: error => {
        captureException(error, {
          extra: {
            isSubscribingFreeTier: true,
          },
        });
        openToast({
          text: 'An error occured subscribing to the free tier. Please try again.',
          variant: 'error',
        });
      },
    },
  );

  return { subscribeFreeTier, isSubscribingFreeTier };
}
