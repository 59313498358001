import { type FC, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  type FiltersSchema,
  filtersState,
  initialFilters,
  useMySubscribers,
} from '../../hooks/useMySubscribers';
import { useStableCallback } from '../../hooks/useStableCallback';
import type { SubscribersFiltersBottomsheetProps } from '../../types/bottomsheetTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Checkbox } from '../input/Checkbox';

type InternalState = {
  sort: FiltersSchema['sort'];
  subscribers: boolean | undefined;
  freeTier: boolean | undefined;
  cancelled: boolean | undefined;
};

const SubscribersFiltersBottomsheet: FC<SubscribersFiltersBottomsheetProps> = ({
  withVaultTheme,
}) => {
  const { refetch } = useMySubscribers();

  const { closeBottomsheet } = useBottomsheetContainer();

  const { sort, status, tierLevel } = useSnapshot(filtersState);

  const [state, setState] = useState<InternalState>({
    sort,
    cancelled: undefined,
    freeTier: undefined,
    subscribers: undefined,
  });

  const applyFilters = useStableCallback(() => {
    filtersState.sort = state.sort;
    filtersState.status = !!state.cancelled ? 'INACTIVE' : undefined;
    filtersState.tierLevel =
      state.subscribers && !state.freeTier
        ? 'PAID'
        : !state.subscribers && state.freeTier
          ? 'FREE'
          : undefined;

    refetch();
    closeBottomsheet();
  });

  useEffect(() => {
    if (!!sort || !!status || !!tierLevel) {
      setState(prev => ({
        ...prev,
        cancelled: status == null ? undefined : status === 'INACTIVE',
        freeTier: tierLevel == null ? undefined : tierLevel === 'FREE',
        subscribers: tierLevel == null ? undefined : tierLevel === 'PAID',
      }));
    }
  }, [sort, status, tierLevel]);

  return (
    <View className="w-full overflow-x-hidden md2:flex md2:h-full md2:flex-col md2:justify-center">
      <View className="my-[20px] flex w-full flex-col items-center justify-center gap-6 md2:px-6">
        <View className="flex w-full flex-col items-center justify-center gap-3 px-6">
          <Text
            className={twMerge(
              'text-center font-title text-title-l font-medium',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          >
            Sort & view
          </Text>

          <Text
            className={twMerge(
              'self-start text-center font-base text-base-m font-medium text-base500',
              withVaultTheme ? 'text-vault_text/50' : 'text-base500',
            )}
          >
            Date joined
          </Text>
        </View>

        <View className="flex w-full flex-col self-start md:w-[91%]">
          <Button
            label="Recent"
            type="secondary"
            className={twMerge(
              'mb-[1px]',
              withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined,
            )}
            position="top"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Recent</Text>
                <input
                  type="radio"
                  className={twMerge(
                    '!m-0',
                    withVaultTheme ? 'vault-theme-radio' : 'default-radio',
                  )}
                  checked={state.sort === 'DESC'}
                />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, sort: 'DESC' }))}
            fullWidthLabel
          />
          <Button
            label="Oldest"
            type="secondary"
            position="bottom"
            className={withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined}
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Oldest</Text>
                <input
                  type="radio"
                  className={twMerge(
                    '!m-0',
                    withVaultTheme ? 'vault-theme-radio' : 'default-radio',
                  )}
                  checked={state.sort === 'ASC'}
                />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, sort: 'ASC' }))}
            fullWidthLabel
          />
        </View>

        <View className="flex w-full flex-col items-center justify-center gap-3 px-6">
          <Text
            className={twMerge(
              'self-start text-center font-base text-base-m font-medium',
              withVaultTheme ? 'text-vault_text/50' : 'text-base500',
            )}
          >
            Show
          </Text>
        </View>

        <View className="flex w-full flex-col self-start md:w-[91%]">
          <Button
            label="Paid Members"
            type="secondary"
            className={twMerge(
              'mb-[1px]',
              withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined,
            )}
            position="top"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Paid Members</Text>
                <Checkbox selected={!!state.subscribers} withVaultTheme={withVaultTheme} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, subscribers: !state.subscribers }))}
            fullWidthLabel
          />
          <Button
            label="Free Tier"
            className={twMerge(
              'mb-[1px]',
              withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined,
            )}
            type="secondary"
            position="middle"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Free Tier</Text>
                <Checkbox selected={!!state.freeTier} withVaultTheme={withVaultTheme} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, freeTier: !state.freeTier }))}
            fullWidthLabel
          />
          <Button
            label="Cancelled"
            type="secondary"
            position="bottom"
            className={withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined}
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Cancelled</Text>
                <Checkbox selected={!!state.cancelled} withVaultTheme={withVaultTheme} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, cancelled: !state.cancelled }))}
            fullWidthLabel
          />
        </View>

        <View className="flex w-full flex-col items-center justify-center gap-6 self-start md:w-[91%]">
          <Button
            label="Apply"
            isExternal
            type="primary"
            className={twMerge(
              'w-full',
              withVaultTheme ? 'bg-vault_accent text-vault_accent_text' : undefined,
            )}
            linkClassName="w-full"
            onClick={applyFilters}
          />

          <Button
            label="Reset"
            onClick={() => {
              Object.assign(filtersState, initialFilters);
              setState({
                sort: 'DESC',
                cancelled: undefined,
                freeTier: undefined,
                subscribers: undefined,
              });
            }}
            type="default"
            className={twMerge(
              'w-full !font-title !text-title-s !font-medium',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          />
        </View>
      </View>
    </View>
  );
};

export { SubscribersFiltersBottomsheet };
