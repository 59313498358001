import { type UIEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { debounce, type DebouncedFunc } from 'lodash-es';
import { faChevronRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useWindow } from '../../hooks/useWindow';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ReceiptUI } from '../membership/ReceiptUI';
import { DSPRow } from './DSPRow';
import { getDSPName } from './helpers';
import { CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

export const CampaignHowItWorksView = () => {
  const { artistHandle } = useArtistHandle();
  const { isDesktop } = useWindow();
  const { loggedInUser } = useAuthContext();
  const { fields } = useCampaignForm();

  const INTRO_CAROUSEL_DATA = useMemo(() => {
    const baseData = [
      {
        title: 'How it works',
        description: `Create your ${fields.campaignType === CampaignType.Presave ? 'pre-save' : 'release'} page for fans to save your upcoming release to their library on Spotify and Apple Music.`,
        component: 'how',
      },
      {
        title: 'Show me the receipts!',
        description:
          'Fans earn a receipt when they pre-save or stream your release, so you can identify your most loyal and engaged fans.',
        component: 'receipt',
      },
    ];

    if (fields.campaignType === CampaignType.Presave) {
      baseData.splice(1, 0, {
        title: 'Release ready',
        description:
          'On release day, this converts into a release page for fans to go stream your music. You can customize which links appear here.',
        component: 'release',
      });
    }

    return baseData;
  }, [fields.campaignType]);

  const randomMemberId = useMemo(() => Math.floor(Math.random() * 10000000), []);

  const randomSerialId = useMemo(() => Math.floor(Math.random() * 1000), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [offset, setOffset] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(prev => (prev + 1) % INTRO_CAROUSEL_DATA.length);
  };

  const prevSlide = () => {
    setCurrentSlide(prev => (prev - 1 + INTRO_CAROUSEL_DATA.length) % INTRO_CAROUSEL_DATA.length);
  };

  const onScroll: DebouncedFunc<UIEventHandler<HTMLDivElement>> = useMemo(
    () =>
      debounce(
        e => {
          const container = e.target as HTMLDivElement;
          const scrollLeft = container.scrollLeft;
          const child = container.firstChild as HTMLElement | null;
          const childWidth = child?.getBoundingClientRect().width || 0;
          const totalWidth = childWidth * INTRO_CAROUSEL_DATA.length;

          let newIndex = Math.round(scrollLeft / childWidth) % INTRO_CAROUSEL_DATA.length;
          const newOffset = Math.floor(scrollLeft / totalWidth);

          if (newIndex < 0) {
            newIndex += INTRO_CAROUSEL_DATA.length;
          }

          if (newIndex !== currentSlide || newOffset !== offset) {
            setCurrentSlide(newIndex);
            setOffset(newOffset);
          }

          // Implement infinite scroll
          if (scrollLeft < childWidth / 2) {
            container.scrollLeft += totalWidth;
            setOffset(prev => prev + 1);
          } else if (scrollLeft > totalWidth * 1.5 - childWidth / 2) {
            container.scrollLeft -= totalWidth;
            setOffset(prev => prev - 1);
          }
        },
        100,
        { trailing: true },
      ),
    [currentSlide, offset, INTRO_CAROUSEL_DATA.length],
  );

  useEffect(() => {
    const scrollToSlide = (index: number) => {
      const container = containerRef.current;
      if (container) {
        const child = container.firstChild as HTMLElement | null;
        const childWidth = child?.getBoundingClientRect().width || 0;
        container.scrollLeft = childWidth * (index + offset * INTRO_CAROUSEL_DATA.length);
      }
    };

    scrollToSlide(currentSlide);
  }, [currentSlide, offset, INTRO_CAROUSEL_DATA.length]);

  const renderCarouselContent = useMemo(() => {
    return (component: string | undefined) => {
      if (!component) return null;
      switch (component) {
        case 'how':
        case 'release':
          return (
            <View className="relative aspect-[280/378] h-full max-h-[378px] min-h-[322px] w-full max-w-[280px] select-none overflow-hidden md2:aspect-[300/425] md2:max-h-[425px] md2:max-w-[300px]">
              <img
                src="https://d3jznoddta6qri.cloudfront.net/public/iPhone_intro.png"
                alt="iphone"
                className="z-above2 h-full w-full object-contain"
              />
              <View className="absolute bottom-[24px] z-above3 w-[275px] rounded-xl bg-base800 md2:bottom-[38px] md2:w-[300px]">
                <View className="flex flex-col gap-4 p-4">
                  <DSPRow
                    key={ThirdPartyPlatform.Spotify}
                    type={fields.campaignType || CampaignType.Stream}
                    dspInfo={{
                      key: ThirdPartyPlatform.Spotify,
                      name: getDSPName(ThirdPartyPlatform.Spotify),
                      uri: 'https://spotify.com/',
                      buttonText: fields.campaignType === CampaignType.Stream ? 'Play' : 'Pre-save',
                      showLink: true,
                    }}
                    onClick={undefined}
                    loading={false}
                    campaignCurrentState={undefined}
                    disabled
                    isPreview
                  />
                  <DSPRow
                    key={ThirdPartyPlatform.AppleMusic}
                    type={fields.campaignType || CampaignType.Stream}
                    dspInfo={{
                      key: ThirdPartyPlatform.AppleMusic,
                      name: getDSPName(ThirdPartyPlatform.AppleMusic),
                      uri: 'https://music.apple.com/',
                      buttonText: fields.campaignType === CampaignType.Stream ? 'Play' : 'Pre-add',
                      showLink: true,
                    }}
                    onClick={undefined}
                    loading={false}
                    campaignCurrentState={undefined}
                    disabled
                    isPreview
                  />
                  {component === 'release' && (
                    <DSPRow
                      key={ThirdPartyPlatform.Discord}
                      type={fields.campaignType || CampaignType.Stream}
                      dspInfo={{
                        key: ThirdPartyPlatform.Discord,
                        name: getDSPName(ThirdPartyPlatform.Discord),
                        uri: 'https://discord.com/',
                        buttonText: 'Sign up',
                        showLink: true,
                      }}
                      onClick={undefined}
                      loading={false}
                      campaignCurrentState={undefined}
                      disabled
                      isPreview
                    />
                  )}
                </View>
              </View>
            </View>
          );
        case 'receipt':
          return (
            <ReceiptUI
              className="aspect-[280/378] h-full max-h-[378px] min-h-[322px] w-[unset] max-w-[280px] select-none md2:aspect-[300/425] md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]"
              title="MOMA 212"
              receiptNumber={randomSerialId}
              username="@yourname"
              memberId={randomMemberId}
              artistHandle={loggedInUser?.artist?.mainLinkValue || artistHandle || ''}
              createdAt={new Date().toISOString()}
              type={fields.campaignType === CampaignType.Presave ? 'pre-save' : 'streaming'}
            />
          );
        default:
          return null;
      }
    };
  }, [
    fields.campaignType,
    randomSerialId,
    randomMemberId,
    loggedInUser?.artist?.mainLinkValue,
    artistHandle,
  ]);

  return (
    <View className="flex w-full flex-1 flex-col items-center justify-center">
      <View className="mb-20 flex w-full flex-col items-center justify-center">
        {isDesktop ? (
          <View className="flex flex-row items-center gap-8">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="aspect-square cursor-pointer rounded-full bg-vault_text/10 p-2 text-[16px] text-vault_text"
              onClick={prevSlide}
            />
            {renderCarouselContent(INTRO_CAROUSEL_DATA[currentSlide]?.component)}
            <FontAwesomeIcon
              icon={faChevronRight}
              className="aspect-square cursor-pointer rounded-full bg-vault_text/10 p-2 text-[16px] text-vault_text"
              onClick={nextSlide}
            />
          </View>
        ) : (
          <View
            containerRef={containerRef}
            className="no-scrollbar flex min-h-[322px] w-full flex-shrink-[1] snap-x snap-mandatory flex-row items-center overflow-x-auto"
            onScroll={onScroll}
          >
            {[...INTRO_CAROUSEL_DATA, ...INTRO_CAROUSEL_DATA, ...INTRO_CAROUSEL_DATA].map(
              (item, index) => (
                <motion.div key={index} className="h-full w-full flex-none snap-center">
                  <View className="z-above4 flex h-full flex-col items-center justify-center">
                    {renderCarouselContent(item.component)}
                  </View>
                </motion.div>
              ),
            )}
          </View>
        )}

        {/* Dots */}
        <View className="z-above4 mt-7 flex justify-center">
          {INTRO_CAROUSEL_DATA.map((_, index) => (
            <View
              key={index}
              className={`mx-1 h-2 w-2 rounded-full ${
                index === currentSlide ? 'bg-vault_accent' : 'bg-base500'
              }`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </View>

        <View className="mt-5 flex flex-[1.4] flex-shrink-[10] flex-col items-center justify-start">
          {/* Text content */}
          <View className="mt-2 box-border w-full">
            <View className="relative">
              <View className="box-border flex flex-col items-center justify-center overflow-hidden rounded-lg text-center">
                {INTRO_CAROUSEL_DATA[currentSlide]?.title && (
                  <Text className="mb-2 text-[min(28px,4vh)]/[min(34px,5vh)] font-semibold text-vault_text">
                    {INTRO_CAROUSEL_DATA[currentSlide]?.title}
                  </Text>
                )}

                {INTRO_CAROUSEL_DATA[currentSlide]?.description && (
                  <Text className="box-border h-28 w-full px-4 text-[min(16px,2.5vh)]/[min(20px,3.125vh)] text-vault_text/50 md2:w-5/6">
                    {INTRO_CAROUSEL_DATA[currentSlide]?.description}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
