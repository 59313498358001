import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation, useQuery } from '../../../graphql/client';
import {
  AppleMusicPresaveDocument,
  GetCampaignAppleMusicPresaveStatusDocument,
  type MusicCampaignViewFragment,
  ThirdPartyPlatform,
} from '../../../graphql/generated';
import { useAppleMusicAuth } from '../../../hooks/appleMusic/useAppleMusicAuth';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

gql(/* GraphQL */ `
  query GetCampaignAppleMusicPresaveStatus(
    $input: QueryReleaseCampaignAppleMusicPresaveStatusInput!
  ) {
    releaseCampaignAppleMusicPresaveStatus(input: $input) {
      id
      status
    }
  }

  mutation AppleMusicPresave($input: MutationAppleMusicPresaveInput!) {
    appleMusicPresave(input: $input) {
      __typename
      ... on MutationAppleMusicPresaveSuccess {
        __typename
      }

      ... on Error {
        __typename
        message
      }
    }
  }
`);

export function useAppleMusicPresaveCampaign({
  campaign,
}: {
  campaign: MusicCampaignViewFragment | null | undefined;
}) {
  const [justConnected, setJustConnected] = useState(false);
  const { loggedInUser } = useAuthContext();

  const { value: appleMusicConnectEnabled } = useGate(FEATURE_GATES.VAULT_APPLE_MUSIC_CONNECT);

  const { openToast } = useToast();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();

  const appleMusicAuth = useAppleMusicAuth({
    enabled: appleMusicConnectEnabled,
  });

  const isAppleMusicAccountLinked = !!loggedInUser?.appleMusicAuthConnections.length;
  const isAppleMusicAccountConnected =
    appleMusicAuth.type === 'apple-music-already-linked' ||
    appleMusicAuth.type === 'apple-music-connected-without-user';

  const alreadyConnected =
    (isAppleMusicAccountLinked || isAppleMusicAccountConnected) && !!appleMusicAuth.userToken;

  const { data, isLoading: loadingPresaveStatus } = useQuery(
    GetCampaignAppleMusicPresaveStatusDocument,
    {
      staleTime: 0,
      variables: !!campaign?.id && {
        input: {
          releaseCampaignId: campaign.id,
          appleMusicAuthUserToken: appleMusicAuth.userToken,
        },
      },
      enabled: !!appleMusicAuth.userToken || !!loggedInUser?.id,
    },
  );

  const { mutateAsync: presaveAppleMusicMutation, isLoading: isPresaving } = useMutation(
    AppleMusicPresaveDocument,
    {
      retry: 5,
    },
  );

  const alreadyConnectedLogic = useCallback(
    async ({ campaign }: { campaign: MusicCampaignViewFragment }) => {
      try {
        const { data } = await presaveAppleMusicMutation(
          {
            input: {
              releaseCampaignId: campaign.id,
              appleMusicAuthUserToken: appleMusicAuth.userToken,
            },
          },
          {
            onSuccess: () => {
              trackEvent({
                type: EVENTS.PRESAVE,
                properties: {
                  artistHandle: campaign.artist.linkValue,
                  campaignId: campaign.id,
                  platform: ThirdPartyPlatform.AppleMusic,
                },
              });

              navigate(
                artistNavigationPath(
                  campaign.artist.linkValue,
                  `/s/${campaign.linkValue}/claim`,
                  'step=claim&source=apple',
                ),
              );

              openToast({
                text: 'The song has been pre-saved.',
                variant: 'success',
              });
            },
          },
        );

        if (data.appleMusicPresave.__typename === 'NotFoundError') {
          openToast({
            text: 'The event campaign does not exist.',
            variant: 'error',
          });
        }
      } catch (error) {
        captureException(error, {
          tags: {
            feature: 'Presave Campaign',
          },
          extra: {
            artistHandle: campaign.artist.linkValue,
            campaignId: campaign.id,
            platform: ThirdPartyPlatform.AppleMusic,
          },
        });
        openToast({
          text: 'There was an error pre-saving the release',
          variant: 'error',
        });
      }
    },
    [appleMusicAuth.userToken, navigate, openToast, presaveAppleMusicMutation],
  );

  const presave = useCallback(async () => {
    if (!campaign) return;

    if (alreadyConnected) {
      alreadyConnectedLogic({ campaign });
      return;
    }

    try {
      if (appleMusicAuth.type === 'connect') {
        await appleMusicAuth.connect();
      } else if (appleMusicAuth.type === 'connected-without-api-confirmation') {
        await appleMusicAuth.connectWithApi();
      } else if (appleMusicAuth.type === 'link-apple-music') {
        await appleMusicAuth.link();
      }

      setJustConnected(true);
    } catch (error) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.CUSTOM,
        customBottomsheetProps: {
          body: (
            <View className="mx-4 flex flex-col gap-4 text-center">
              <Text className="font-title text-title-l">
                Sorry, you must have an Apple Music Premium account
              </Text>

              <Text className="text-base-l text-base400">
                We only support Apple Music Premium accounts. You can still stream on your favorite
                platform without connecting.
              </Text>

              <Button
                className="mt-6"
                type="primary"
                label="Ok"
                onClick={() => closeBottomsheet()}
              />
            </View>
          ),
        },
      });
    }
  }, [
    alreadyConnected,
    alreadyConnectedLogic,
    appleMusicAuth,
    campaign,
    closeBottomsheet,
    openBottomsheet,
  ]);

  useEffect(() => {
    async function connect() {
      if (!campaign) return;
      await alreadyConnectedLogic({ campaign });
      setJustConnected(false);
    }
    if (justConnected && !!campaign && alreadyConnected) {
      connect();
    }
  }, [
    alreadyConnected,
    alreadyConnectedLogic,
    campaign,
    isAppleMusicAccountConnected,
    justConnected,
  ]);

  return {
    presave,
    isPresaving,
    presaveStatus: data?.data.releaseCampaignAppleMusicPresaveStatus?.status,
    loadingPresaveStatus,
  };
}
