import { gql } from '@soundxyz/gql-string';
import { useQuery } from '../../graphql/client';
import { LastMembershipReceiptDocument } from '../../graphql/generated';
import { AppleMusicConnectState } from '../appleMusic/useAppleMusicAuth';
import { SpotifyConnectState } from '../spotify/useSpotifyAuth';

gql(/* GraphQL */ `
  query LastMembershipReceipt(
    $artistHandle: String
    $receiptType: ArtistMembershipReceiptTypenames
    $releaseCampaignId: UUID!
    $spotifyAuthCode: String
    $appleMusicUserToken: String
  ) {
    lastMembershipReceipt(
      artistHandle: $artistHandle
      receiptType: $receiptType
      releaseCampaignId: $releaseCampaignId
      spotifyAuthCode: $spotifyAuthCode
      appleMusicUserToken: $appleMusicUserToken
    ) {
      id
      ...MembershipReceipt
    }
  }
`);

export function useLastMembershipReceipt({
  artistHandle,
  releaseCampaignId,
  source,
}: {
  artistHandle: string | undefined;
  releaseCampaignId: string;
  source: 'apple' | 'spotify' | null;
}) {
  const spotifyAuth = SpotifyConnectState.useStore();
  const appleMusicAuth = AppleMusicConnectState.useStore();

  const { data, isLoading } = useQuery(LastMembershipReceiptDocument, {
    variables: {
      artistHandle,
      releaseCampaignId,
      ...(source === 'spotify' && { spotifyAuthCode: spotifyAuth.value?.code }),
      ...(source === 'apple' && { appleMusicUserToken: appleMusicAuth.value?.userToken }),
    },
    enabled: !!source,
    staleTime: 0,
  });

  return {
    data,
    isLoading,
  };
}
