import type { ToastVariant } from '../components/common/Toast';
import type { MessageReactionTypeInput, ThirdPartyPlatform } from '../graphql/generated';
import type { BottomsheetType, OpenBottomsheetEventProperties } from './bottomsheetTypes';
import type { Satisfies } from './utils';

export type VaultPageSelected =
  | 'vault_page'
  | 'upload_page'
  | 'message_channel_page'
  | 'membership_page';

const EVENTS = {
  //Generic
  BACK: 'Back',
  CLOSE_BOTTOMSHEET: 'Close Bottomsheet',
  CLOSE_MENU: 'Close Menu',
  CLOSE_TOAST: 'Close Toast',
  COPY: 'Copy',
  EMPTY_VIEW: 'Empty View',
  EXTERNAL_LINK: 'External Link',
  OPEN_BOTTOMSHEET: 'Open Bottomsheet',
  OPEN_MENU: 'Open Menu',
  MENU_NAVIGATE: 'Menu Navigate',
  SECONDARY_MENU_NAVIGATE: 'Secondary Menu Navigate',
  NEXT: 'Next',
  RESEND_CODE: 'Resend Code',
  RETRY: 'Retry',
  SETTINGS_NAVIGATE: 'Settings Navigate',
  SIGN_IN: 'Sign In',
  SIGN_OUT: 'Sign Out',
  SKIP: 'Skip',
  TAB_NAVIGATE: 'Tab Navigate',
  GET_APP: 'Get App',
  CONTINUE_ON_BROWSER: 'Continue on Browser',

  //Confirmation Bottomsheet Events
  CONFIRM_CANCEL: 'Confirm Cancel',
  CONFIRM_SUCCES: 'Confirm Success',
  CONT_TO_SUBSCRIBE: 'Continue To Subscribe',
  CONT_TO_JOIN_FREE_TIER: 'Continue To Join Free Tier',
  EXIT_CANCEL: 'Exit Flow Cancel',
  EXIT_DISCARD: 'Exit Flow Discard',

  //Audio
  CLOSE_FULLSCREEN_AUDIO: 'Close Fullscreen Audio Player',
  EDIT_TRACK: 'Edit Track',
  EDIT_TRACK_SNIPPET: 'Edit Track Snippet',
  SAVE_TRACK_SNIPPET: 'Save Track Snippet',
  EDIT_TRACK_SPLITS: 'Edit Track Splits',
  EDIT_TRACK_SPLITS_VALIDATION_ERROR: 'Edit Track Splits Validation Error',
  NEXT_TRACK: 'Next Track',
  PAUSE_TRACK: 'Pause Track',
  PLAY_TRACK: 'Play Track',
  PREV_TRACK: 'Previous Track',
  REMOVE_TRACK: 'Remove Track',
  SEEK_TRACK: 'Seek Track',
  SHARE_TRACK: 'Share Track',
  UPLOAD_TRACK: 'Upload Track',
  LOADED_TRACK: 'Loaded Track',

  //Vault
  ARTIST_SOCIAL: 'Artist Social',
  CANCEL_SUBSCRIPTION: 'Cancel Subscription',
  RESUBSCRIBE: 'Resubscribe',
  SUBSCRIBE: 'Subscribe',
  SUBSCRIPTION_SUCCESS: 'Subscription Success',
  SUBSCRIBE_FREE_TIER_SUCCESS: 'Subscribe Free Tier Success',
  VAULT_INVITES: 'Vault Invites',
  SHARE_VAULT_INVITE: 'Share Vault Invite',
  ANNOUNCEMENTS: 'Announcements',

  //Messaging
  ATTACH_TRACK: 'Attach Track',
  BAN_USER: 'Ban User',
  DELETE_MESSAGE: 'Delete Message',
  PIN_MESSAGE: 'Pin Message',
  POST_TO_CHAT: 'Post To Chat',
  REACT_TO_MESSAGE: 'React To Message',
  REMOVE_ATTACH_AUDIO: 'Remove Attached Audio',
  REMOVE_ATTACH_TRACK: 'Remove Attached Track',
  SEND_MESSAGE: 'Send Message',
  SET_REPLY_TO_MESSAGE: 'Set Reply To Message',
  COPY_MESSAGE: 'Copy Message',
  UNBAN_USER: 'Unban User',
  UNPIN_ALL: 'Unpin All',
  UNPIN_MESSAGE: 'Unpin Message',
  VIEW_REACTORS: 'View Reactors',
  VIEW_REPLY: 'View Reply',

  //Comments
  SEND_COMMENT_MESSAGE: 'Send Comment Message',
  DELETE_COMMENT_MESSAGE: 'Delete Comment Message',
  REACT_TO_COMMENT_MESSAGE: 'React To Comment Message',
  CLICK_COMMENT_TIMESTAMP: 'Click Comment Timestamp',

  //User
  ADD_PAYMENT_METHOD: 'Add Payment Method',
  EDIT_PROFILE: 'Edit Profile',
  EDIT_PROFILE_PICTURE: 'Edit Profile Picture',
  REMOVE_PAYMENT_METHOD: 'Remove Payment Method',
  REMOVE_PROFILE_PICTURE: 'Remove Profile Picture',
  SAVE_PAYMENT_METHOD: 'Save Payment Method',
  DELETE_ACCOUNT: 'Delete Account',

  //FOLDERS
  CREATE_FOLDER: 'Create Folder',
  MOVE_TO_FOLDER: 'Move To Folder',
  REMOVE_VAULT_CONTENTS: 'Remove Vault Contents',
  EDIT_FOLDER: 'Edit Folder',

  //Artist
  BECOME_AN_ARTIST: 'Become an Artist',
  EDIT_ARTIST_PROFILE: 'Edit Artist Profile',
  CUSTOMIZE_VAULT: 'Customize Vault',
  EDIT_BANK_ACCT: 'Edit Bank Account',
  EXPORT_CSV: 'Export CSV',
  SAVE_SPLIT_CHANGE: 'Save Split Change',
  SAVE_SPLIT_CHANGE_VALIDATION_ERROR: 'Save Split Change Validation Error',
  CREATE_ANNOUNCEMENT: 'Create Announcement',
  CREATE_RELEASE_CAMPAIGN: 'Create Release Campaign',
  UPDATE_RELEASE_CAMPAIGN: 'Update Release Campaign',

  //CAMPAIGNS
  SPOTIFY_PLAY: 'Spotify Play',
  APPLE_PLAY: 'Apple Play',
  CLAIM_RECEIPT: 'Claim Receipt',
  PRESAVE: 'Presave Campaign',
} as const;

export type EventType = (typeof EVENTS)[keyof typeof EVENTS];

export type EventProperties = Satisfies<
  Record<EventType, null | Record<string, unknown>>,
  {
    //Generic
    ['Back']: { type: 'logo' | 'default' };
    ['Close Bottomsheet']: {
      bottomsheetType: BottomsheetType;
      type: 'swipe' | 'pointer' | 'esc' | 'other' | 'button';
    };
    ['Close Menu']: { type: 'button' | 'pointer' | 'other' | 'button' };
    ['Close Toast']: { toastType?: (typeof ToastVariant)[keyof typeof ToastVariant] };
    ['Copy']: { type: 'phone' | 'email' | 'link' | 'invite-link' };
    ['Empty View']: null;
    ['Get App']: {
      vaultId: string | null;
      trackId: string | null;
      interstitial: 'first_chat' | 'first_track' | 'first_snippet_share' | 'first_media';
    };
    ['Continue on Browser']: {
      vaultId: string | null;
      interstitial: 'first_chat' | 'first_track' | 'first_snippet_share' | 'first_media';
    };
    ['External Link']: { type: 'x' | 'facebook' | 'instagram' };
    ['Open Bottomsheet']: OpenBottomsheetEventProperties;
    ['Open Menu']: null;
    ['Menu Navigate']:
      | { type: 'my_vault' | 'explore' | 'settings' | 'vaults' | 'about' }
      | { type: 'vault'; vaultId: string; artistId: string | null };
    ['Secondary Menu Navigate']: {
      artistHandle: string | null | undefined;
      path: string;
    };
    ['Next']: { type: string };
    ['Resend Code']: null;
    ['Retry']: { type?: string; component: 'error_view' | 'toast' | 'other' };
    ['Settings Navigate']: { type: string };
    ['Skip']: { type: string };
    ['Sign In']: null;
    ['Sign Out']: null;
    ['Tab Navigate']: { type: VaultPageSelected; isPreview?: boolean };

    //Confirmation Bottomsheet Events
    ['Confirm Cancel']: { event?: EventType };
    ['Confirm Success']: { event?: EventType };
    ['Continue To Subscribe']: { artistHandle: string };
    ['Continue To Join Free Tier']: { artistHandle: string };
    ['Exit Flow Cancel']: { event?: EventType };
    ['Exit Flow Discard']: { event?: EventType };

    //Audio
    ['Close Fullscreen Audio Player']: {
      trackId: string;
      trackTitle: string | undefined;
      vaultId: string;
      artistId: string | null;
      onSwipe?: true;
    };
    ['Edit Track']: { vaultContentId: string; artistHandle: string };
    ['Edit Track Snippet']: { vaultContentId: string; artistHandle: string };
    ['Save Track Snippet']: { vaultContentId: string; artistHandle: string };
    ['Edit Track Splits']: {
      vaultContentId: string;
      artistHandle: string;
      details: Array<{
        id: string;
        name: string;
        emailAddress: string;
        role: string;
        percentBps: number;
        isOwnSplit: boolean;
      }>;
    };
    ['Next Track']: { trackId: string };
    ['Pause Track']:
      | {
          trackId: string;
          percentComplete: number;
          vaultId: string;
          artistId?: string;
          isPreview: boolean | undefined;
          component:
            | 'fullscreen_audio_player'
            | 'bottom_audio_player'
            | 'message_attachment'
            | 'artist_page'
            | 'message_channel_layout'
            | 'minimized_message_attachment'
            | 'waveform'
            | undefined;
        }
      | { component: 'upload_view'; vaultId: string };
    ['Play Track']:
      | {
          trackId: string;
          percentComplete: number;
          vaultId: string;
          artistId?: string;
          loadedTrack?: boolean;
          title: string | null;
          isPreview: boolean | undefined;
          component:
            | 'fullscreen_audio_player'
            | 'bottom_audio_player'
            | 'message_attachment'
            | 'artist_page'
            | 'message_channel_layout'
            | 'minimized_message_attachment'
            | 'waveform'
            | 'track_comments'
            | 'track_landing_page'
            | undefined;
        }
      | { component: 'upload_view'; vaultId: string };
    ['Previous Track']: { trackId: string };
    ['Remove Track']: { trackId: string; vaultId: string; artistId: string };
    ['Seek Track']: {
      trackId: string;
      vaultId: string;
    } & (
      | {
          type: 'timeline';
          artistId: string | null;
        }
      | {
          type: 'waveform';
          loadedTrack: boolean;
          artistId?: string;
        }
    );
    ['Share Track']: { trackId: string; vaultId: string };
    ['Upload Track']: { vaultId: string; artistHandle: string };

    //Vault
    ['Artist Social']: {
      artistId: string;
      vaultId: string;
      type: 'instagram' | 'spotify' | 'tiktok' | 'other';
      link: string;
    };
    ['Cancel Subscription']: {
      subscriptionId: string;
      vaultId: string;
      artistId: string | null;
      isFreeTier: boolean;
    };
    ['Resubscribe']: { vaultId: string; artistId: string | null };
    ['Subscribe']: { vaultId: string; artistId: string };
    ['Subscription Success']: {
      artistId: string;
      vaultId: string;
      newCard: boolean;
      isFreeTrial: boolean;
    };
    ['Subscribe Free Tier Success']: null;
    ['Vault Invites']: { artistHandle: string };
    ['Announcements']: { artistHandle: string };
    ['Share Vault Invite']: { type: 'email' | 'imessage' };

    //Folders
    ['Create Folder']: {
      vaultId: string;
      newFolderId: string;
    };
    ['Move To Folder']: {
      contentIds: {
        type: 'VaultFolder' | 'VaultTrack' | 'VaultImage' | 'VaultVideo';
        id: string;
      }[];
    };
    ['Remove Vault Contents']: {
      contentIds: {
        type: 'VaultFolder' | 'VaultTrack' | 'VaultImage' | 'VaultVideo';
        id: string;
      }[];
    };
    ['Edit Folder']: {
      folderId: string;
      vaultId: string;
    };

    //Messaging
    ['Attach Track']: { trackId: string; vaultId: string };
    ['Ban User']: { userId: string };
    ['Delete Message']: { messageId: string; timeActive: number };
    ['Pin Message']: { messageId: string };
    ['Post To Chat']: { trackId: string; vaultId: string };
    ['React To Message']: { messageId: string; reactionType: MessageReactionTypeInput };
    ['Set Reply To Message']: { messageId: string; type: 'elevated' | 'drag' };
    ['Copy Message']: { messageId: string };
    ['Remove Attached Audio']: null;
    ['Remove Attached Track']: { trackId: string };
    ['Send Message']: {
      channelId: string;
      attachmentIds: string[];
      contentLength: number;
      isVaultArtist: boolean;
      containsMessageRecording: boolean;
      isReply: boolean;
      imageAttachmentsCount: number;
    };
    ['Unban User']: { userId: string };
    ['Unpin All']: { channelId: string };
    ['Unpin Message']: { messageId: string };
    ['View Reactors']: {
      messageId: string;
      reactionType: MessageReactionTypeInput | null;
      count: number;
    };
    ['View Reply']: { messageId: string };

    //Comments
    ['Send Comment Message']: {
      trackId: string;
      messageChannelId: string;
      content: string;
      artistId?: string | null;
      isFreeTierSubscription?: boolean;
    };
    ['Delete Comment Message']: { trackId: string; messageId: string };
    ['React To Comment Message']: {
      trackId: string;
      messageId: string;
      reactionType: MessageReactionTypeInput;
    };
    ['Click Comment Timestamp']: { trackId: string; messageId: string; seconds: number };

    //User
    ['Add Payment Method']: null;
    ['Edit Profile']: null;
    ['Edit Profile Picture']: null;
    ['Remove Payment Method']: null;
    ['Remove Profile Picture']: null;
    ['Save Payment Method']: null;
    ['Delete Account']: { userId: string };

    //Artist
    ['Become an Artist']: null;
    ['Edit Artist Profile']: { artistId: string };
    ['Customize Vault']: { vaultId: string };
    ['Edit Bank Account']: null;
    ['Export CSV']: { artistId: string };
    ['Loaded Track']: {
      trackId: string | null;
      vaultId: string | null;
      artistId: string | null;
      title: string | null;
      duration: number;
      src: string;
      isHlsLibrarySupported: boolean;
    };
    ['Save Split Change']: {
      artistHandle: string;
      vaultContentId: string;
      data: {
        id: string;
        name: string;
        emailAddress: string;
        role: string;
        percentBps: number;
        isOwnSplit: boolean;
      };
    };
    ['Save Split Change Validation Error']: {
      artistHandle: string;
      vaultContentId: string;
      data: {
        id: string;
        name: string;
        emailAddress: string;
        role: string;
        percentBps: number;
        isOwnSplit: boolean;
      };
      error: string;
    };
    ['Edit Track Splits Validation Error']: {
      artistHandle: string;
      vaultContentId: string;
      data: Array<{
        id: string;
        name: string;
        emailAddress: string;
        role: string;
        percentBps: number;
        isOwnSplit: boolean;
      }>;
      error: string;
    };
    ['Create Announcement']: {
      artistId: string;
      announcementId: string;
    };
    ['Create Release Campaign']: {
      presaveEnabled: boolean;
      artistHandle: string;
    };
    ['Update Release Campaign']: {
      presaveEnabled: boolean;
      artistHandle: string;
    };

    //CAMAIGN
    ['Spotify Play']: { campaignId: string };
    ['Apple Play']: { campaignId: string };
    ['Claim Receipt']: { campaignId: string };
    ['Presave Campaign']: {
      campaignId: string;
      platform: ThirdPartyPlatform;
      artistHandle: string;
    };
  }
>;

export { EVENTS };

export type EventObject<Event extends EventType> = {
  type: Event;
  properties: EventProperties[Event];
};
