import { useCallback, useState } from 'react';
import { captureException, captureMessage } from '@sentry/react';
import { PlayerType } from '../../audio/AudioController';
import { useSpotifyContext } from '../../contexts/SpotifyContext';
import { ReleaseCampaignContentType } from '../../graphql/generated';
import { SpotifyErrorSchema } from '../../schemas/spotify';
import { useSpotifyAccessToken } from './useSpotifyAccessToken';

/**
 * Hook to play a track on Spotify
 *
 * @param spotifyUri - The Spotify URI of the track to play
 * @param campaignId - The ID of the campaign to play
 * @param contentType - The content type of the campaign
 * @returns An object containing the onClick function, the isLoading state, the isError state, and the isDisabled state
 */
export function usePlaySpotifyTrack({
  spotifyUri,
  campaignId,
  contentType,
}: {
  spotifyUri: string | null | undefined;
  campaignId: string | null | undefined;
  contentType: ReleaseCampaignContentType | null | undefined;
}) {
  const { deviceId, playbackState, player, error, setActiveCampaignId } = useSpotifyContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { accessToken } = useSpotifyAccessToken();

  const isPlayingSong = playbackState != null && playbackState.context.uri === spotifyUri;

  const onClick = useCallback(async () => {
    if (
      deviceId == null ||
      accessToken == null ||
      spotifyUri == null ||
      campaignId == null ||
      contentType == null
    )
      return;

    setActiveCampaignId(campaignId);

    PlayerType.current = 'spotify';

    if (isPlayingSong && player != null) {
      player.togglePlay();
      return;
    }

    setIsLoading(true);

    const body =
      contentType === ReleaseCampaignContentType.Album
        ? { context_uri: spotifyUri }
        : { uris: [spotifyUri] };

    await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async resp => {
        if (resp.ok) {
          setIsError(false);
        } else {
          setIsError(true);
          const error = SpotifyErrorSchema.safeParse(await resp.json().catch(() => null));

          captureMessage('Error on spotify play response', {
            tags: {
              feature: 'usePlaySpotifyTrack',
            },
            extra: {
              error,
              spotifyUri,
              deviceId,
              status: resp.status,
              statusText: resp.statusText,
            },
          });
        }
      })
      .catch(error => {
        setIsError(true);
        captureException(error, {
          tags: {
            feature: 'usePlaySpotifyTrack',
          },
          extra: {
            spotifyUri,
            deviceId,
          },
        });
      });

    setIsLoading(false);
  }, [
    accessToken,
    campaignId,
    contentType,
    deviceId,
    isPlayingSong,
    player,
    setActiveCampaignId,
    spotifyUri,
  ]);

  return {
    onClick,
    isLoading,
    isError,
    playbackError: error,
    isDisabled:
      deviceId == null ||
      accessToken == null ||
      spotifyUri == null ||
      campaignId == null ||
      contentType == null ||
      error !== null,
  };
}
